import { dateIsAfter } from '@pumpkincare/shared';

import validateClaimsDates from './validate-claim-dates';

export default function validateClaimSubmissionFields(
  {
    fileList,
    dateList,
    injuryDate,
    totalAmountList,
    vetVisitReason,
    isPreventiveEssentials,
    isInsuranceIllness,
    isInsuranceAccident,
    insuranceStartDate = null,
    medicalRecordsFileList = [],
    selectedVet,
    isMultiVet,
    isOngoing,
  },
  {
    shouldValidateInjuryDate,
    shouldValidateMultiVetAndOngoing,
    shouldValidateClinic,
  } = {}
) {
  let isValid = true;
  let fileListError;
  let vetVisitError;
  let claimTypeError;
  const sectionsWithErrors = [];
  const isInsurance = isInsuranceAccident || isInsuranceIllness;

  if (fileList.length === 0) {
    isValid = false;
    fileListError = 'This field is required.';
    sectionsWithErrors.push('Itemized invoice');
  }

  if (fileList.some(item => item.error)) {
    isValid = false;
    sectionsWithErrors.push('Itemized invoice');
  }

  if (medicalRecordsFileList.some(item => item.error)) {
    isValid = false;
    sectionsWithErrors.push('Medical records');
  }

  if (shouldValidateClinic && !selectedVet) {
    isValid = false;
    sectionsWithErrors.push('Which clinic');
  }

  if (
    shouldValidateMultiVetAndOngoing &&
    isInsurance &&
    typeof isMultiVet !== 'boolean'
  ) {
    isValid = false;
    sectionsWithErrors.push('Were multiple clinics visited');
  }

  const dateListValidated = dateList.map(item => {
    item.dateOfVisitErrorMessage = '';
    item.dateOfVisitError = false;

    const result = validateClaimsDates(
      item.dateOfVisit,
      'Date of visit',
      insuranceStartDate
    );

    if (result !== true) {
      isValid = false;
      item.dateOfVisitErrorMessage = result;
      item.dateOfVisitError = true;
    }

    return item;
  });

  if (dateListValidated.some(item => item.dateOfVisitError === true)) {
    sectionsWithErrors.push('Date of visit');
  }

  const totalAmountListValidated = totalAmountList.map(item => {
    item.amountErrorMessage = '';
    item.amountError = false;

    if (!item.totalAmountClaimed) {
      isValid = false;
      item.amountErrorMessage = 'This field is required.';
      item.amountError = true;
    }

    if (isNaN(item.totalAmountClaimed)) {
      isValid = false;
      item.amountErrorMessage = 'Invalid value.';
      item.amountError = true;
    }

    return item;
  });

  if (totalAmountListValidated.some(item => item.amountError === true)) {
    sectionsWithErrors.push('Total amount claimed');
  }

  if (!isInsuranceAccident && !isInsuranceIllness && !isPreventiveEssentials) {
    isValid = false;
    claimTypeError = 'Please choose a claim type.';
    sectionsWithErrors.push('Type of claim');
  }

  if (shouldValidateInjuryDate && isInsurance) {
    let result = validateClaimsDates(
      injuryDate,
      'Date of accident/illness',
      insuranceStartDate
    );

    if (result === true) {
      const firstValidVisitDate = dateListValidated
        .filter(
          ({ dateOfVisit, dateOfVisitError }) => dateOfVisit && !dateOfVisitError
        )
        .sort((a, b) => new Date(a.dateOfVisit) - new Date(b.dateOfVisit))[0];
      result =
        !firstValidVisitDate ||
        !dateIsAfter(injuryDate, firstValidVisitDate.dateOfVisit);
    }

    if (result !== true) {
      isValid = false;
      sectionsWithErrors.push('Date of accident/illness');
    }
  }

  if (
    shouldValidateMultiVetAndOngoing &&
    isInsurance &&
    typeof isOngoing !== 'boolean'
  ) {
    isValid = false;
    sectionsWithErrors.push('Is this a new condition');
  }

  if (vetVisitReason === '') {
    isValid = false;
    vetVisitError = 'This field is required.';
    sectionsWithErrors.push('Reason for vet visit');
  }

  return {
    fileListError,
    vetVisitError,
    dateListValidated,
    totalAmountListValidated,
    isValid,
    claimTypeError,
    sectionsWithErrors,
  };
}

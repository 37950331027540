export default function validateDirectDepositFields(
  accountHolderName,
  accountNumber,
  accountConfirmation,
  routingNumber,
  routingConfirmation
) {
  const areAccountsMatching = accountNumber === accountConfirmation;
  const areRoutingNumbersMatching = routingNumber === routingConfirmation;
  const isRoutingNumber9digits = routingNumber.length === 9;

  return {
    accountHolderName: !accountHolderName,
    accountNumber: !areAccountsMatching,
    accountConfirmation: !areAccountsMatching,
    routingNumber: !areRoutingNumbersMatching || !isRoutingNumber9digits,
    routingConfirmation: !areRoutingNumbersMatching,
    hasErrors:
      !accountHolderName ||
      !areAccountsMatching ||
      !areRoutingNumbersMatching ||
      !isRoutingNumber9digits,
  };
}

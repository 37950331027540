import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';

import { Spinner } from '@pumpkincare/shared/ui';
import { hasVet, useVetForm } from '@pumpkincare/vets';

import { Paths } from '../../../../app-shell';
import PetCard from './pet-card';

import styles from './my-pet-information.module.css';

function MyPetInformation() {
  const dispatch = useDispatch();

  // TODO: Data source choice feels weird. Verify if we can use user profile data instead https://petlife.atlassian.net/browse/PK-3465
  const { isFetching, data: vetFormData } = useVetForm();

  function onClick(pet) {
    dispatch(
      push(
        generatePath(Paths.AccountVetForm, {
          petId: pet.id,
        })
      )
    );
  }

  function createCard(answer) {
    const { pet, vet } = answer;

    const formattedVet = hasVet(answer)
      ? {
          id: vet.id,
          name: vet.vet_name,
          address: vet.normalized_addr,
        }
      : null;

    return (
      <PetCard
        key={pet.id}
        className={styles.card}
        pet={pet}
        petCompleteInfo={answer}
        vet={formattedVet}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      <h4>My Pet Information</h4>

      <div className={styles.cardsContainer}>
        {isFetching ? <Spinner /> : vetFormData.map(createCard)}
      </div>
    </>
  );
}

export default MyPetInformation;

/* eslint-disable react/jsx-no-target-blank */

import classNames from 'classnames';

import { WWW_URL } from '@pumpkincare/config';
import { LegalBody, Link } from '@pumpkincare/shared/ui';

import styles from './bottom-content-links.module.css';

function BottomContentLinks() {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <LegalBody className={styles.textColor}>
          © {new Date().getFullYear()} Pumpkin Insurance Services Inc. All rights
          reserved
        </LegalBody>
        <div className={styles.linkContainer}>
          <Link href={`${WWW_URL}/terms-of-use/`} target='_blank' rel='noopener'>
            Terms of Use
          </Link>
          <div className={styles.linkDivider}>
            <Link href={`${WWW_URL}/privacy-center/`} target='_blank' rel='noopener'>
              Privacy Center
            </Link>
          </div>
          <div className={styles.linkDivider}>
            <button
              id='ot-sdk-btn'
              className={classNames('ot-sdk-show-settings', styles.onetrustLink)}
            >
              Do Not Sell/Share My Personal Information
            </button>
          </div>
          <div className={styles.linkDivider}>
            <Link
              href={`${WWW_URL}/sitemap_index.xml/`}
              target='_blank'
              rel='noopener'
            >
              Sitemap
            </Link>
          </div>
          <div className={styles.linkDivider}>
            <Link
              href={`${WWW_URL}/underwriting-information/`}
              target='_blank'
              rel='noopener'
            >
              Underwriting
            </Link>
          </div>
          <div className={styles.linkDivider}>
            <Link
              href={`${WWW_URL}/insurance-licenses/`}
              target='_blank'
              rel='noopener'
            >
              Insurance Licenses
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BottomContentLinks;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".monthly-statements_root_85e9b1bc {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.monthly-statements_billingQuestion_737355ec {\n  margin-top: 2px;\n}\n\n.monthly-statements_phone_0c28512d {\n  text-decoration: none;\n  color: var(--bluesCluesBlue);\n}\n\n.monthly-statements_cards_2d725fd5 {\n  margin-top: 6px;\n}", "",{"version":3,"sources":["webpack://src/monthly-statements/view/monthly-statements/monthly-statements.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.billingQuestion {\n  margin-top: 2px;\n}\n\n.phone {\n  text-decoration: none;\n  color: var(--bluesCluesBlue);\n}\n\n.cards {\n  margin-top: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "monthly-statements_root_85e9b1bc",
	"billingQuestion": "monthly-statements_billingQuestion_737355ec",
	"phone": "monthly-statements_phone_0c28512d",
	"cards": "monthly-statements_cards_2d725fd5"
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import queryString from 'query-string';

import { GaLink } from '@pumpkincare/analytics/ui';
import { WWW_URL } from '@pumpkincare/config';
import {
  ARF_MEOW_PHONE_NUMBER,
  getIsLoggedIn,
  PHONE_ARIA_LABEL,
  PHONE_NUMBER_LINK,
  usePartnerBranding,
} from '@pumpkincare/shared';
import { Body2, ButtonStyles, Link, RouteLink } from '@pumpkincare/shared/ui';
import { useLogOut, useUserBranding } from '@pumpkincare/user';

import Paths from '../../paths';

import styles from './mobile-menu.module.css';

function MobileConfirmLogout({ onConfirm }) {
  const [show, setShow] = useState(false);

  return (
    <div>
      {show ? (
        <>
          <div className={styles.confirmLogout}>
            <span>Are you sure you want to logout?</span>
          </div>

          <button
            className={classNames(ButtonStyles.primary, styles.logoutConfirm)}
            onClick={onConfirm}
          >
            Logout
          </button>

          <button
            className={classNames(ButtonStyles.blue, styles.logoutCancel)}
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
        </>
      ) : (
        <RouteLink to='#' onClick={() => setShow(true)}>
          Logout
        </RouteLink>
      )}
    </div>
  );
}

function MobileMenu({ onItemClick }) {
  const { punks684BrandedMemberCenter } = useFlags();
  const isLoggedIn = getIsLoggedIn();
  const logOut = useLogOut();

  const firstElem = useRef();
  const logoElem = useRef();

  const { search } = useLocation();

  const { partner } = queryString.parse(search);
  const {
    data: { secondary_logo_url: secondaryLogo },
  } = usePartnerBranding(partner);

  const {
    data: { secondary_logo_url: userPartnerLogo },
  } = useUserBranding();

  function handleLogoutClick() {
    logOut().then(() => {
      global.location.href = '/';
    });
  }

  function handleLoginLogoutClick(e) {
    isLoggedIn ? e.preventDefault() : onItemClick();
  }

  useEffect(() => {
    logoElem.current.focus();
  }, [logoElem]);

  return (
    <>
      <div className={styles.backdrop} onClick={onItemClick}>
        &nbsp;
      </div>

      <div className={styles.root}>
        <div className={styles.logo} ref={logoElem} tabIndex='0'>
          <div className={styles.logoWrapper}>
            <button
              autoFocus={true}
              className={ButtonStyles.unset}
              onClick={onItemClick}
              ref={firstElem}
            >
              <img alt='hide menu' src='/assets/images/iconImages/chevron.svg' />
            </button>
            <div className={styles.logoSection}>
              <Link
                href={'/'}
                target='_blank'
                rel='noopener noreferrer'
                onClick={onItemClick}
                title='Home'
                tabIndex='-1'
                className={styles.logoContainer}
              >
                <img
                  alt=''
                  role='presentation'
                  src='/assets/images/Pumpkin_Logo_White.svg'
                  className={styles.logoImg}
                />
              </Link>
              {punks684BrandedMemberCenter && (secondaryLogo || userPartnerLogo) ? (
                <>
                  <div className={styles.logoSeparator} />
                  <Link
                    href={'/'}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={onItemClick}
                    title='Home'
                    tabIndex='-1'
                    className={styles.logoContainer}
                  >
                    <img
                      alt=''
                      role='presentation'
                      src={secondaryLogo || userPartnerLogo}
                      className={styles.partnerLogoImg}
                    />
                  </Link>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.menuContainer}>
          <ul className={styles.menu}>
            <li className={styles.menuItem} onClick={onItemClick}>
              <RouteLink to={Paths.Profile}>
                <Body2 isBold className={styles.textColor}>
                  My Profile
                </Body2>
              </RouteLink>
            </li>

            <li className={styles.menuItem} onClick={onItemClick}>
              <RouteLink to={Paths.Claims}>
                <Body2 isBold className={styles.textColor}>
                  Claims
                </Body2>
              </RouteLink>
            </li>

            <li className={styles.menuItem} onClick={onItemClick}>
              <RouteLink to={Paths.Billing}>
                <Body2 isBold className={styles.textColor}>
                  Billing
                </Body2>
              </RouteLink>
            </li>

            <li className={styles.menuItem} onClick={onItemClick}>
              <RouteLink to={Paths.PetPolicy}>
                <Body2 isBold className={styles.textColor}>
                  My Pet’s Plan
                </Body2>
              </RouteLink>
            </li>
          </ul>

          <div className={styles.menuDivider} />

          <ul className={styles.links}>
            <li className={styles.menuItem} onClick={handleLoginLogoutClick}>
              {isLoggedIn ? (
                <MobileConfirmLogout onConfirm={handleLogoutClick} />
              ) : (
                <RouteLink to={Paths.Login}>Login</RouteLink>
              )}
            </li>

            <li className={styles.menuItem} onClick={onItemClick}>
              <Link
                href={`${WWW_URL}/underwriting-information`}
                rel='noopener noreferrer'
                target='_blank'
              >
                Underwriting
              </Link>
            </li>
          </ul>

          <GaLink
            href={PHONE_NUMBER_LINK}
            aria-label={PHONE_ARIA_LABEL}
            data-event='Click Blog'
            data-label='Navigation Bar'
            data-category='About Pumpkin'
            style={{ display: 'block', margin: '0 16px 16px' }}
            tabIndex='-1'
          >
            <button className={classNames(ButtonStyles.blue, styles.callButton)}>
              {ARF_MEOW_PHONE_NUMBER}
            </button>
          </GaLink>

          <span tabIndex='0' onFocus={() => firstElem.current.focus()}>
            &nbsp;
          </span>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;

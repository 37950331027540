import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { usePaperlessAgreement } from '@pumpkincare/shared';
import { ButtonStyles, Radio, Typography } from '@pumpkincare/shared/ui';
import {
  getUserIsPaperless,
  getUserPets,
  useMutateUserPaperless,
  useUserProfile,
  useUssr,
} from '@pumpkincare/user';

import PaperlessCallout from '../paperless-callout';

import styles from './paperless.css';

function Paperless() {
  const {
    data: { paperless_terms_url, terms_version },
  } = usePaperlessAgreement();

  const { data: userProfileData, isFetched: isUserProfileFetched } =
    useUserProfile();
  const isUserPaperless = getUserIsPaperless(userProfileData);

  const { data: userPets, isFetched: isUserFetched } = useUssr(getUserPets);
  const hasPep = userPets.some(pet => !!pet.plans.length);

  const { mutate: mutateUserPaperless } = useMutateUserPaperless();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [paperlessSelected, setPaperlessSelected] = useState(isUserPaperless);

  const isReady = isUserProfileFetched && isUserFetched;
  const isPaperlessSelected = paperlessSelected ?? isUserPaperless;

  useEffect(() => {
    // update local state once we get the data
    setPaperlessSelected(isUserPaperless);
  }, [isUserPaperless]);

  function handlePaperlessConfirm() {
    mutateUserPaperless({
      ...userProfileData,

      is_paperless: true,
      terms_version,
    });
  }

  function handleConfirmClick() {
    mutateUserPaperless({
      ...userProfileData,

      is_paperless: paperlessSelected,
      terms_version,
    });

    setIsConfirmationVisible(false);
  }

  function handleCancelClick() {
    setIsConfirmationVisible(false);

    setPaperlessSelected(isUserPaperless);
  }

  return (
    <div className={styles.root}>
      <h2 className={Typography.h2} style={{ marginTop: '40px' }}>
        Account Documents
      </h2>

      {isReady ? (
        <>
          <h4 className={Typography.h4} style={{ margin: '32px 0 16px' }}>
            Delivery Method
          </h4>

          <div className={styles.radioButton} style={{ marginBottom: '8px' }}>
            <Radio
              label='Mail to shipping address'
              checked={!isPaperlessSelected}
              style={{ width: '100%' }}
              onClick={() => {
                if (paperlessSelected !== false) {
                  setIsConfirmationVisible(true);
                }

                if (isUserPaperless === false) {
                  setIsConfirmationVisible(false);
                }

                setPaperlessSelected(false);
              }}
            />
          </div>

          {!isUserPaperless ? (
            <PaperlessCallout
              hasPep={hasPep}
              paperlessTermsUrl={paperless_terms_url}
              style={{ width: '100%' }}
              onConfirm={handlePaperlessConfirm}
            />
          ) : (
            <>
              <div className={styles.radioButton}>
                <Radio
                  label='View all documents with Paperless'
                  checked={isPaperlessSelected === true}
                  onClick={() => {
                    if (paperlessSelected !== true) {
                      setIsConfirmationVisible(true);
                    }

                    if (isUserPaperless === true) {
                      setIsConfirmationVisible(false);
                    }

                    setPaperlessSelected(true);
                  }}
                />
              </div>

              <div style={{ marginTop: '8px', textAlign: 'center' }}>
                <a href={paperless_terms_url} style={{ fontSize: '14px' }}>
                  Go Paperless with Pumpkin Consent Agreement
                </a>
              </div>
            </>
          )}

          {isConfirmationVisible ? (
            <div className={styles.confirmationPanel}>
              <button
                className={classNames(ButtonStyles.primary, styles.confirm)}
                onClick={handleConfirmClick}
              >
                Save Changes
              </button>

              <button
                className={classNames(ButtonStyles.secondary, styles.decline)}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default Paperless;

import { FETCHING } from '@pumpkincare/shared';
import { Body2, LoaderButton, Modal } from '@pumpkincare/shared/ui';

import usePetsPermissions from '../../hooks/use-pets-permissions';
import ClinicsModalBody from '../clinics-modal-body';

import styles from './clinics-update-modal.module.css';

export default function ClinicsUpdateModal({
  onClose,
  onDelete,
  onUpdate,
  status,
  userPets,
  vet,
}) {
  const {
    petAssociations,
    handlePetAssociationsChange,
    claimsPermitted,
    toggleClaimsPermission,
  } = usePetsPermissions(userPets, vet);
  const isLoading = status === FETCHING;

  function handleUpdate() {
    onUpdate({
      pet_associations: petAssociations,
      can_file_claim: claimsPermitted,
      can_view_data: true,
    });
  }

  function handleDelete() {
    onDelete(vet.id);
  }

  return (
    <Modal
      onClose={onClose}
      classes={{ container: styles.container, content: styles.content }}
    >
      <h2 className={styles.header}>
        {vet.permissions.needsDataAction ? 'New Vet Clinic' : 'Update Vet Clinic'}
      </h2>
      {vet.permissions.needsDataAction ? (
        <Body2 style={{ marginBottom: '12px' }}>
          The following clinic has requested permission to be added to your account:
        </Body2>
      ) : null}

      <div className={styles.clinic}>
        <Body2>{vet.vet_name}</Body2>
        <Body2>{vet.normalized_addr}</Body2>
      </div>

      <ClinicsModalBody
        userPets={userPets}
        petAssociations={petAssociations}
        onPetChange={handlePetAssociationsChange}
        claimsPermitted={claimsPermitted}
        toggleClaimsPermission={toggleClaimsPermission}
      />

      <LoaderButton
        color='primary'
        classes={{ root: styles.button }}
        onClick={handleUpdate}
        isLoading={isLoading}
      >
        Save Clinic
      </LoaderButton>
      <LoaderButton
        color='secondary'
        classes={{ root: styles.button }}
        onClick={handleDelete}
        isLoading={isLoading}
      >
        Delete Vet Clinic
      </LoaderButton>
    </Modal>
  );
}

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".divider_divider_2b9cb3f9 {\n  background-color: var(--pusheenGray);\n  border: none;\n  height: 1px;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://src/user/view/shared/divider/divider.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,YAAY;EACZ,WAAW;EACX,SAAS;AACX","sourcesContent":[".divider {\n  background-color: var(--pusheenGray);\n  border: none;\n  height: 1px;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "divider_divider_2b9cb3f9"
};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react/jsx-no-target-blank */
import { useState } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import queryString from 'query-string';

import { WWW_URL } from '@pumpkincare/config';
import { getIsLoggedIn, usePartnerBranding } from '@pumpkincare/shared';
import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import {
  ButtonStyles,
  LegalBody,
  Link,
  RouteLink,
  Tabs,
  Typography,
} from '@pumpkincare/shared/ui';
import { useLogOut, useUserBranding } from '@pumpkincare/user';

import Paths from '../../paths';
import MobileMenu from '../mobile-menu';

import styles from './nav-bar.module.css';

const BASE_TABS = [
  {
    label: 'My Profile',
    to: Paths.Profile,
    value: Paths.Profile,
  },
  {
    label: 'Claims',
    to: Paths.Claims,
    value: Paths.Claims,
  },
  {
    label: 'Billing',
    to: Paths.Billing,
    value: Paths.Billing,
  },
  {
    label: `My Pet's Plan`,
    to: Paths.PetPolicy,
    value: Paths.PetPolicy,
  },
];

export default function NavBar() {
  const { punks684BrandedMemberCenter } = useFlags();
  const location = useLocation();
  const { search } = useLocation();

  const logOut = useLogOut();

  const isLoggedIn = getIsLoggedIn();

  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const { partner } = queryString.parse(search);
  const {
    data: { primary_logo_url: partnerLogo },
  } = usePartnerBranding(partner);

  const {
    data: { primary_logo_url: userPartnerLogo },
  } = useUserBranding();

  const isRegisterPage = !!matchPath(location.pathname, {
    path: Paths.Registration,
  });
  const isLoginPage = !!matchPath(location.pathname, {
    path: Paths.Login,
  });

  const mobileMenuButtonClassName = classNames(styles.mobileMenuButton);
  const partnerLogoClassName = classNames(styles.partnerLogoWrapper, {
    [styles.partnerSectionVisibility]: !isLoginPage && !isRegisterPage,
  });
  function handleMobileMenuItemClick() {
    setIsHamburgerOpen(!isHamburgerOpen);
  }

  function handleLogoutClick() {
    logOut().then(() => {
      global.location.href = '/';
    });
  }

  return (
    <nav>
      <div className={styles.topMenuContainer}>
        <div>
          <ul className={styles.menuWrapper}>
            <li className={styles.menuItem}>
              <LegalBody className={styles.legalBody}>
                <Link href={PHONE_NUMBER_LINK}>{`${ARF_MEOW_PHONE_NUMBER}`}</Link>
              </LegalBody>
            </li>

            <li className={styles.contactEmail}>
              <LegalBody className={styles.legalBody}>
                <Link href={CONTACT_EMAIL_LINK} className={styles.contactEmail}>
                  {CONTACT_EMAIL}
                </Link>
              </LegalBody>
            </li>

            <li className={styles.liDivider}>
              <div className={styles.vDivider}>&nbsp; </div>
            </li>

            <li className={styles.menuItem}>
              <LegalBody className={styles.legalBody}>
                <Link
                  href={`${WWW_URL}/underwriting-information/`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Underwriting
                </Link>
              </LegalBody>
            </li>
            {isLoggedIn ? (
              <>
                <li className={styles.liDivider}>
                  <div className={styles.vDivider}>&nbsp; </div>
                </li>
                <li className={styles.menuItem}>
                  <LegalBody className={styles.legalBody}>
                    <Link href='#' onClick={handleLogoutClick}>
                      Logout
                    </Link>
                  </LegalBody>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      </div>

      <div className={styles.mainMenuContainer}>
        <div style={{ display: 'flex' }}>
          {isLoggedIn && !isRegisterPage ? (
            <div
              className={mobileMenuButtonClassName}
              onClick={handleMobileMenuItemClick}
            >
              &nbsp;
            </div>
          ) : null}

          <Link
            href={WWW_URL}
            target='_blank'
            rel='noopener'
            title='Home'
            tabIndex='-1'
          >
            <div className={styles.logoWrapper}>
              <img
                alt='Pumpkin Logo'
                src='/assets/images/Pumpkin_Logo_Blue.svg'
                className={styles.logo}
              />
            </div>
          </Link>

          {punks684BrandedMemberCenter && (userPartnerLogo || partnerLogo) ? (
            <div className={partnerLogoClassName}>
              <div className={styles.separatorColumn} />
              <div className={styles.logoWrapper}>
                <img
                  alt={`${partner || 'partner'} logo`}
                  src={partnerLogo || userPartnerLogo}
                  className={styles.logoPartner}
                />
              </div>
            </div>
          ) : null}
        </div>

        {isLoggedIn && !isRegisterPage ? (
          <>
            <div className={styles.tabsContainer}>
              <div className={styles.tabItemsContainer}>
                <Tabs
                  tabsData={BASE_TABS}
                  activeValue={location.pathname}
                  classes={{
                    container: styles.desktopContainer,
                    tabCopy: classNames(Typography.body1, styles.tabCopy),
                    tab: styles.tab,
                    activeCopy: styles.activeCopy,
                  }}
                />
              </div>
            </div>

            <div className={styles.buttonsContainer}>
              <RouteLink
                to={Paths.ClaimsSubmission}
                className={styles.claimSubmissionLink}
              >
                <button
                  className={classNames(ButtonStyles.primary, styles.claimsButton)}
                  data-testid='file-claim-button-navbar'
                >
                  File a Claim
                </button>
              </RouteLink>
            </div>
          </>
        ) : null}
      </div>

      {isHamburgerOpen ? (
        <div className={styles.mobileMenuContainer}>
          <MobileMenu onItemClick={handleMobileMenuItemClick} />
        </div>
      ) : null}
    </nav>
  );
}

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isPrevent4 } from '@pumpkincare/plans';
import {
  ARF_MEOW_PHONE_NUMBER,
  formatCurrency,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  Modal,
  Typography,
} from '@pumpkincare/shared/ui';

import HappyAnniversaryPage from '../happy-anniversary-page';

import styles from './renewal-modal.module.css';

export function getTotalDiscounts(policy) {
  const groupDiscountPercentage = policy.group_discount_percentage
    ? 100 - policy.group_discount_percentage * 100
    : 0;

  const multiDiscountPercentage = policy.multiple_pet_discount_percentage
    ? 100 - policy.multiple_pet_discount_percentage * 100
    : 0;

  return multiDiscountPercentage + groupDiscountPercentage;
}

export default function RenewalModal(props) {
  const {
    onClose,
    configPets,
    isAnnuallyCharged,
    onDownloadPolicy,
    onDownloadPlan,
    transactionFee,
  } = props;

  const { renewalPlan: petPlan } = configPets.filter(pet => pet.renewalPolicy)[0];

  const isPEP4 = petPlan?.plan ? isPrevent4(petPlan.plan) : false;

  const hasPreventLines = configPets.some(
    pet => pet.preventLineItemsFormatted.length
  );

  const subTotalAmount = configPets
    .filter(pet => pet.renewalPolicy)
    .reduce((acc, pet) => acc + pet.renewalSubtotal, 0);

  function handleOnClose(closeType) {
    onClose(closeType);
  }

  return (
    <Modal
      onClose={closeType => handleOnClose(closeType)}
      classes={{ container: styles.modalContainer }}
      aria-label='Renewal popup'
    >
      <div className={styles.topSection}>
        <HappyAnniversaryPage pets={configPets} />

        <div className={styles.bodySection}>
          <img
            alt='band aid'
            className={styles.headerIcon}
            src='/assets/images/iconImages/bandaids-red.svg'
          />

          <div>
            <span className={classNames(Typography.h4, styles.title)}>
              Pet Insurance Monthly Premium Changes
            </span>

            <Body2>
              You may notice the monthly cost of your insurance is higher than last
              year. Here’s why:
            </Body2>

            <div className={styles.infoWrapper}>
              <div className={classNames(styles.info)}>
                <Body2 isBold>Age of Pet:</Body2>

                <Body2>
                  As your pet’s age increases, it is standard for monthly premiums to
                  increase to account for the increased risk of developing certain
                  health issues.
                </Body2>
              </div>

              <div className={classNames(styles.info)}>
                <Body2 isBold>Cost of Veterinary Care:</Body2>

                <Body2>
                  If the cost of veterinary care in your state has increased, it is
                  standard for monthly premiums to increase to account for the
                  additional finances needed to cover these rising costs.
                </Body2>
              </div>
            </div>
          </div>
        </div>

        {hasPreventLines ? (
          <div className={styles.bodySection}>
            <img
              className={styles.headerIcon}
              src='/assets/images/icon_box.png'
              alt=''
            />

            <div>
              <span className={classNames(Typography.h4, styles.title)}>
                Preventive Essentials Package Upgrade
              </span>

              <Body2>
                You may notice your Preventive Essentials package looks different
                than last year. Here’s why:
              </Body2>

              <div className={styles.infoWrapper}>
                {isPEP4 && petPlan ? (
                  <div className={classNames(styles.info)}>
                    <Body2 isBold>New Vet Designed Package:</Body2>

                    <Body2>
                      {`We partnered with ${petPlan.plan.vet_name} to create a custom package just for patients like yours.`}
                    </Body2>
                  </div>
                ) : null}

                <div className={classNames(styles.info)}>
                  <Body2 isBold>More Wellness Benefits:</Body2>

                  <Body2>
                    Get a full refund this year for more routine wellness care
                    services your vet recommends.
                  </Body2>
                </div>

                <div className={classNames(styles.info)}>
                  <Body2 isBold>Total Cost:</Body2>

                  <Body2>
                    If the cost looks different, it’s because this package covers
                    different services.
                  </Body2>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className={styles.planDetails}>
        <div className={styles.planBoard}>
          <div className={styles.plan}>
            {configPets
              .filter(pet => pet.policy)
              .map((petItem, index) => {
                const {
                  isRenewalDay,
                  renewalPolicy,
                  preventLineItemsFormatted: preventItems,
                  pet,
                  renewalPlan,
                  policy,
                  policyRenewalAnnualReimbursement,
                  policyRenewalAnnualDeductible,
                  policyRenewalAnnualLimit,
                  renewalPolicyPrice,
                  renewalPlanPrice,
                } = petItem;

                const totalDiscount = getTotalDiscounts(policy);

                return renewalPolicy && !isRenewalDay ? (
                  <div key={index}>
                    <span className={classNames(Typography.h4, styles.headerText)}>
                      {pet.name}’s Renewal Plan
                    </span>

                    <div className={styles.priceSummary}>
                      <div className={styles.phiWrapper}>
                        <div>
                          <Body2 isBold>Pet Insurance</Body2>

                          <div className={styles.listSection}>
                            <LegalBody>
                              <span className={Typography.bold}>
                                {policyRenewalAnnualReimbursement}
                              </span>{' '}
                              Reimbursement Rate Fur All
                            </LegalBody>

                            <LegalBody>
                              <span className={Typography.bold}>
                                {`$${policyRenewalAnnualDeductible}`}
                              </span>{' '}
                              Annual Deductible
                            </LegalBody>

                            <LegalBody>
                              <span className={Typography.bold}>
                                {`$${policyRenewalAnnualLimit / 1000}K`}
                              </span>{' '}
                              Annual Coverage Limit
                            </LegalBody>
                          </div>

                          <button
                            onClick={() => onDownloadPolicy(renewalPolicy.id)}
                            className={classNames(
                              Typography.link,
                              styles.downloadButton
                            )}
                          >
                            <img
                              alt={`download`}
                              src='/assets/images/iconImages/download.svg'
                            />
                            Download Insurance Policy
                          </button>
                        </div>

                        <div className={styles.priceBox}>
                          <Body2 isBold>
                            {`${formatCurrency(renewalPolicyPrice)} / ${
                              isAnnuallyCharged ? 'yr' : 'mo'
                            }`}
                          </Body2>

                          {totalDiscount ? (
                            <span className={styles.discountTag}>
                              {`${totalDiscount}% Discount
                            Applied`}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {renewalPlan && preventItems.length > 0 ? (
                        <div className={styles.pepWrapper}>
                          <div>
                            <Body2 isBold>Preventive Essentials</Body2>

                            {isPEP4 ? (
                              <div className={styles.designedWrapper}>
                                <img
                                  alt=''
                                  src='/assets/images/iconImages/design-star.png'
                                  className={styles.designIcon}
                                />

                                <span className={styles.designedText}>
                                  {`Designed by ${renewalPlan?.plan.vet_name}`}
                                </span>
                              </div>
                            ) : null}

                            <div>
                              <div className={styles.listSection}>
                                {preventItems.map(({ item }, key) => (
                                  <LegalBody key={key}>
                                    <span className={styles.plusSign}>+</span>
                                    {item}
                                  </LegalBody>
                                ))}
                              </div>

                              <button
                                onClick={() => onDownloadPlan(petItem)}
                                className={classNames(
                                  Typography.link,
                                  styles.downloadButton
                                )}
                              >
                                <img
                                  alt={`download`}
                                  src='/assets/images/iconImages/download.svg'
                                />
                                Download Customer Agreement
                              </button>
                            </div>
                          </div>

                          <div>
                            <Body2 isBold>{`${formatCurrency(renewalPlanPrice)} / ${
                              isAnnuallyCharged ? 'yr' : 'mo'
                            }`}</Body2>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null;
              })}

            <div className={styles.footerLine}>
              <Body2 isBold>{isAnnuallyCharged ? 'Yearly Total' : 'Subtotal'}</Body2>

              <Body2 isBold>{`${formatCurrency(subTotalAmount)} / ${
                isAnnuallyCharged ? 'yr' : 'mo'
              }`}</Body2>
            </div>

            {isAnnuallyCharged ? null : (
              <div className={styles.footerLine}>
                <Body2>
                  {`An additional ${formatCurrency(transactionFee, {
                    hideZero: true,
                  })} installment fee per transaction may apply, please see your policy documents for details.`}
                </Body2>
              </div>
            )}
          </div>

          <div>
            <div className={styles.contact}>
              <div className={styles.iconWrapper}>
                <img
                  src='/assets/images/multipet_pawcierge.svg'
                  className={styles.contactIcon}
                  alt=''
                />
              </div>

              <Body1 isBold className={styles.contactTitle}>
                Have questions? Give us a bark!
              </Body1>

              <Body2 isBold className={styles.contactSubtitle}>
                Some things we can discuss:
              </Body2>

              <div className={styles.contactBodyText}>
                <LegalBody>Annual deductible options</LegalBody>

                <LegalBody>Annual limit options</LegalBody>

                {!hasPreventLines ? (
                  <LegalBody>Preventive Essentials options</LegalBody>
                ) : null}
              </div>

              <Body2 isBold className={styles.contactSubtitle}>
                Connect with our care team:
              </Body2>

              <LegalBody className={styles.contactInfo}>
                <a
                  href={PHONE_NUMBER_LINK}
                  className={classNames(Typography.h5, styles.linkText)}
                >
                  {`${ARF_MEOW_PHONE_NUMBER}`}
                </a>
              </LegalBody>

              <LegalBody>(1-866-273-6369)</LegalBody>
            </div>

            <div className={styles.bottomIconWrapper}>
              <img
                src='/assets/images/iconImages/heart-clifford.png'
                className={styles.heartIcon}
                alt=''
              />
            </div>

            <Body2 className={styles.greetings}>
              Thank you for being part of the Pumpkin Family! We hope this coming
              year is full of healthy days and happy treats ahead.
            </Body2>
          </div>
        </div>
      </div>

      <div className={styles.footerSection}>
        <button
          onClick={closeType => handleOnClose(closeType)}
          className={classNames(ButtonStyles.cta)}
        >
          Continue to Plan
        </button>
      </div>
    </Modal>
  );
}

RenewalModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  configPets: PropTypes.array.isRequired,
  isAnnuallyCharged: PropTypes.bool,
  onDownloadPolicy: PropTypes.func,
  onDownloadPlan: PropTypes.func,
};

RenewalModal.defaultProps = {
  onClose: () => {},
  configPets: [],
  isAnnuallyCharged: false,
  onDownloadPolicy: () => {},
  onDownloadPlan: () => {},
};

import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { formatISODate } from '@pumpkincare/shared';
import { Body2, LegalBody, MoneyLabel } from '@pumpkincare/shared/ui';

import { CLAIM_TYPE_PREVENT, CLAIM_TYPE_PROTECT } from '../../../constants';
import downloadClaimDocument from '../../../thunks/download-claim-document';

import styles from './claim-card.module.css';

function ClaimCard(props) {
  const dispatch = useDispatch();
  const {
    claim,
    documentUrl,
    type,
    claimId,
    amountPaid,
    amountClaimed,
    status,
    submissionDate,
    sendReimbursementToVet,
    submittedBy,
  } = props;
  const { reimburseVet } = useFlags();

  const showPaidToVet =
    reimburseVet === true &&
    type === CLAIM_TYPE_PREVENT &&
    sendReimbursementToVet &&
    amountPaid > 0;

  function downloadClickHandler() {
    dispatch(downloadClaimDocument(type, claim.id));
  }

  return (
    <>
      <div
        key={claim.id}
        className={styles.claimsWrapper}
        data-testid={`claim-${claimId.toString().substring(0, 5)}-${type
          .replace(/\W+/g, '-')
          .toLowerCase()}-card`}
      >
        <Body2 className={styles.claimIdDiv}>Claim Id: {claimId}</Body2>

        <div className={styles.claimTypeContainer}>
          <LegalBody className={styles.claimTypeDiv}>{type}</LegalBody>
          {showPaidToVet ? (
            <div className={styles.paidToVetContainer}>
              <img
                src='/assets/images/iconImages/first-aid-kit.svg'
                alt='first-aid-kit'
                className={styles.paidToVetIcon}
              />
              <LegalBody className={styles.paidToVet}>Paid to Vet</LegalBody>
            </div>
          ) : null}
        </div>

        {submittedBy ? (
          <div className={styles.submittedByContainer}>
            <img
              src='/assets/images/iconImages/nav-bar/medical-bag.png'
              alt='medical-bag'
              role='presentation'
              className={styles.submittedByIcon}
            />
            <LegalBody>Submitted by: {submittedBy}</LegalBody>
          </div>
        ) : null}

        <div className={styles.valuesContainer}>
          <div>
            <Body2 className={styles.claimLabel}>Date of Submission:</Body2>
            <Body2 className={styles.claimLabel}>Status:</Body2>
            <Body2 className={styles.claimLabel}>Amount Claimed:</Body2>
            <Body2 className={styles.claimLabel}>Amount Paid:</Body2>
          </div>
          <div>
            <LegalBody className={styles.claimData}>
              {formatISODate(submissionDate)}
            </LegalBody>
            <LegalBody className={styles.claimData}>{status}</LegalBody>
            <LegalBody className={styles.claimData}>
              {amountClaimed ? <MoneyLabel value={amountClaimed / 100} /> : '-'}
            </LegalBody>
            <LegalBody className={styles.claimData}>
              {amountPaid ? <MoneyLabel value={amountPaid / 100} /> : '-'}
            </LegalBody>
          </div>

          {documentUrl && (
            <div>
              <div className={styles.documentDiv} onClick={downloadClickHandler}>
                <img
                  className={styles.documentIcon}
                  src='/assets/images/iconImages/document-icon.svg'
                />
                <LegalBody className={styles.documentBody}>
                  {type === CLAIM_TYPE_PROTECT
                    ? 'Insurance Explanation of Benefits'
                    : 'Preventive Essentials Claim Summary'}
                </LegalBody>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ClaimCard;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.footer_root_8c810f39 {\n  background: var(--zeroWhite);\n}", "",{"version":3,"sources":["webpack://src/app-shell/view/footer/footer.module.css"],"names":[],"mappings":";AACA;EACE,4BAA4B;AAC9B","sourcesContent":["\n.root {\n  background: var(--zeroWhite);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "footer_root_8c810f39"
};
export default ___CSS_LOADER_EXPORT___;

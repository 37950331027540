import { setUserIsRegistrationLoading } from '../index';

export default function forgotPassword(email) {
  return (dispatch, getState, { userService }) => {
    dispatch(setUserIsRegistrationLoading(true));

    return userService.forgotPassword(email).finally(() => {
      dispatch(setUserIsRegistrationLoading(false));
    });
  };
}

import { useState } from 'react';
import classNames from 'classnames';

import { ButtonStyles, Checkbox, Typography } from '@pumpkincare/shared/ui';

import styles from './paperless-callout.css';

import plantImg from './plant.png';

function PaperlessCallout({
  hasPep = false,
  paperlessTermsUrl = '',
  onConfirm = () => {},
}) {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isTermsError, setIsTermsError] = useState(false);

  function handleConfirmClick() {
    if (isTermsAccepted) {
      setIsTermsError(false);
      onConfirm();
    } else {
      setIsTermsError(true);
    }
  }

  function handleTermsCheckboxChange() {
    setIsTermsAccepted(!isTermsAccepted);
    setIsTermsError(false);
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img src={plantImg} alt='' /> <h4 className={Typography.h4}>Go paperless</h4>
      </div>

      <div className={styles.copy}>
        Now the environmentally-friendly option is also the most convenient option
        for you to manage your Pumpkin account. Skip the paper – you can access all
        of these documents online, anytime:
        <ul>
          <li>Pet insurance policy and billing documents</li>
          {hasPep ? <li>Preventive Essentials Customer Agreement</li> : null}
          <li>Explanation of Benefits for completed insurance claims</li>
          {hasPep ? (
            <li>Summary for completed Preventive Essentials claims</li>
          ) : null}
        </ul>
        We’ll even send you an email when new documents are available online. You can
        opt out anytime.
      </div>

      <div style={{ marginBottom: '24px' }}>
        <Checkbox
          isError={isTermsError}
          label={
            <>
              I have read{' '}
              <a href={paperlessTermsUrl}>
                Go Paperless with Pumpkin Consent Agreement
              </a>{' '}
              and understand that I will no longer receive documents in the mail.
            </>
          }
          checked={isTermsAccepted}
          style={{ padding: 0 }}
          onChange={handleTermsCheckboxChange}
        />

        {isTermsError ? (
          <div
            className={Typography.legalBody}
            style={{ marginTop: '8px', color: 'var(--rowlfRed)' }}
          >
            Looks like you forgot to agree to the paperless consent agreement
          </div>
        ) : null}
      </div>

      <button
        className={classNames(ButtonStyles.basePrimary, styles.cta)}
        onClick={handleConfirmClick}
        style={{ maxWidth: '280px' }}
      >
        Go Paperless
      </button>
    </div>
  );
}

export default PaperlessCallout;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h2.clinics-new-modal_header_bcff2570 {\n    color: var(--bluesCluesBlue);\n}\n\nbutton.clinics-new-modal_button_99f9029e, button.clinics-new-modal_cancel_d822d9a9 {\n    display: block;\n    margin: 20px auto 0;\n    width: 100%;\n}\n\nbutton.clinics-new-modal_cancel_d822d9a9 {\n    color: var(--dinoBlue);\n    height: inherit;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n    .clinics-new-modal_content_1cb9f703 {\n        padding: 32px 16%;\n    }\n}", "",{"version":3,"sources":["webpack://src/user/view/clinics-new-modal/clinics-new-modal.module.css"],"names":[],"mappings":"AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["@value b-desktop from '~@pumpkincare/shared/ui/breakpoints';\n\nh2.header {\n    color: var(--bluesCluesBlue);\n}\n\nbutton.button, button.cancel {\n    display: block;\n    margin: 20px auto 0;\n    width: 100%;\n}\n\nbutton.cancel {\n    color: var(--dinoBlue);\n    height: inherit;\n}\n\n@media b-desktop {\n    .content {\n        padding: 32px 16%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"header": "clinics-new-modal_header_bcff2570",
	"button": "clinics-new-modal_button_99f9029e",
	"cancel": "clinics-new-modal_cancel_d822d9a9",
	"content": "clinics-new-modal_content_1cb9f703"
};
export default ___CSS_LOADER_EXPORT___;

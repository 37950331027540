// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".my-pet-information_cardsContainer_ead95b65 {\n  display: flex;\n  max-width: 67%;\n  flex-wrap: wrap;\n}\n\n.my-pet-information_card_153a78f6 {\n  margin: 0 16px 16px 0;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + " {\n  .my-pet-information_card_153a78f6 {\n    margin-bottom: 24px;\n    margin-right: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/user/view/profile/my-pet-information/my-pet-information.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,mBAAmB;IACnB,eAAe;EACjB;AACF","sourcesContent":["@value b-mobile from '~@pumpkincare/shared/ui/breakpoints';\n\n.cardsContainer {\n  display: flex;\n  max-width: 67%;\n  flex-wrap: wrap;\n}\n\n.card {\n  margin: 0 16px 16px 0;\n}\n\n@media b-mobile {\n  .card {\n    margin-bottom: 24px;\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-mobile": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + "",
	"cardsContainer": "my-pet-information_cardsContainer_ead95b65",
	"card": "my-pet-information_card_153a78f6"
};
export default ___CSS_LOADER_EXPORT___;

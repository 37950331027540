import Paths from '../paths';

const ACCOUNT = `Account`;

const title = {
  [Paths.Account]: ACCOUNT,
  [Paths.AccountVetForm]: 'Account | Vet Form',
  [Paths.Billing]: `${ACCOUNT} | Billing`,
  [Paths.Profile]: `${ACCOUNT} | Profile`,
  [Paths.PetPolicy]: `${ACCOUNT} | Pet Plan`,
  [Paths.Claims]: `${ACCOUNT} | Claims`,
  [Paths.ClaimsSubmission]: `${ACCOUNT} | File a claim`,
  [Paths.Login]: 'Login',
  [Paths.ForgotPassword]: 'Forgot Password',
  [Paths.Registration]: 'Registration',
};

export default function getPageTitle(pathname) {
  return (
    title[pathname] || 'Pumpkin® Pet Insurance | The best treat is a healthy life™'
  );
}

export default function uploadFile(file, rawFile, type) {
  return (dispatch, getState, { userService }) => {
    return userService
      .obtainSignedUrlForFiles([rawFile], type)
      .then(signedUrls => {
        const signedUrlParams = signedUrls[file.name];
        file.key = signedUrlParams.fields.key;
        return userService.uploadAttachments([rawFile], signedUrls);
      })
      .then(() => {
        return file;
      });
  };
}

import { useState } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useBooleanInput } from '@pumpkincare/shared';
import { Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';
import { getUserId, getUserPets, useUssr } from '@pumpkincare/user';

import useUserVetsLegacy from '../../hooks/use-user-vets';
import ClinicsDeleteModal from '../clinics-delete-modal';
import ClinicsNewModal from '../clinics-new-modal';
import ClinicsUpdateModal from '../clinics-update-modal';

import styles from './clinics-page.module.css';

export default function ClinicsPage() {
  const { allowMultiVetsPerPet } = useFlags();

  const [activeUpdateClinic, setUpdateClinic] = useState(null);
  const [activeDeleteClinic, setDeleteClinic] = useState(null);
  const [isNewClinicModalOpen, toggleNewClinicModal] = useBooleanInput(false);

  const { data: userData } = useUssr();
  const userId = getUserId(userData);
  const userPets = getUserPets(userData);

  const {
    vets: clinics,
    statusOfPost,
    statusOfUpdate,
    handleUserVetDelete,
    handleUserVetPost,
    handleUserVetUpdate,
  } = useUserVetsLegacy(userId);

  function handleCloseUpdateModal() {
    setUpdateClinic(null);
  }

  function handleOpenDeleteClinic(e = {}, clinic) {
    e.stopPropagation?.();
    setDeleteClinic(clinic);
  }

  function handleCloseDeleteClinic() {
    setDeleteClinic(null);
  }

  function handleSwapToDeleteModal() {
    const clinic = activeUpdateClinic;
    handleCloseUpdateModal();
    handleOpenDeleteClinic({}, clinic);
  }

  function handleDeleteClinic() {
    handleUserVetDelete((activeDeleteClinic || activeUpdateClinic).id).then(
      handleCloseDeleteClinic
    );
  }

  function handleUpdateClinic(data) {
    handleUserVetUpdate(activeUpdateClinic.id, data).then(handleCloseUpdateModal);
  }

  function handleSubmitNewClinic(vetId, data) {
    handleUserVetPost(vetId, data).then(toggleNewClinicModal);
  }

  return allowMultiVetsPerPet ? (
    <div className={styles.container}>
      <h1 className={styles.title}>My Vet Clinics</h1>

      <div className={styles.clinicsContainer}>
        {clinics.map(clinic => {
          const { needsClaimAction, needsDataAction } = clinic.permissions;
          const needsAction = needsClaimAction || needsDataAction;

          return (
            <div
              key={clinic.id}
              className={classNames(styles.clinic, {
                [styles.clinicAction]: needsAction,
              })}
              role='button'
              onClick={() => setUpdateClinic(clinic)}
            >
              <Body2 style={{ marginBottom: '4px' }}>{clinic.display_name}</Body2>
              <LegalBody>{clinic.normalized_addr}</LegalBody>

              {needsAction ? (
                <LegalBody className={styles.action}>
                  <b>!</b>
                  <span>
                    {needsDataAction
                      ? 'Requested to be added to your vet list.'
                      : 'Requested to file a claim'}
                  </span>
                  <button>{needsDataAction ? 'Accept' : 'Update'}</button>
                </LegalBody>
              ) : null}

              <button
                onClick={e => handleOpenDeleteClinic(e, clinic)}
                className={styles.delete}
              >
                <img src='/assets/images/iconImages/trash.svg' alt='delete clinic' />
              </button>

              <button className={classNames(Typography.body2, styles.update)}>
                Update
              </button>
            </div>
          );
        })}

        <div
          className={classNames(Typography.h4, styles.addNewClinic)}
          role='button'
          onClick={toggleNewClinicModal}
        >
          <span>+</span>
          Add a Vet Clinic
        </div>
      </div>

      {activeUpdateClinic ? (
        <ClinicsUpdateModal
          onClose={handleCloseUpdateModal}
          onDelete={handleSwapToDeleteModal}
          onUpdate={handleUpdateClinic}
          vet={activeUpdateClinic}
          userPets={userPets}
          status={statusOfUpdate}
        />
      ) : null}

      {activeDeleteClinic ? (
        <ClinicsDeleteModal
          onClose={handleCloseDeleteClinic}
          onDelete={handleDeleteClinic}
          status={statusOfUpdate}
        />
      ) : null}

      {isNewClinicModalOpen ? (
        <ClinicsNewModal
          onClose={toggleNewClinicModal}
          userPets={userPets}
          onSubmit={handleSubmitNewClinic}
          status={statusOfPost}
        />
      ) : null}
    </div>
  ) : null;
}

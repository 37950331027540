import { Fragment } from 'react';

import { Tabs } from '@pumpkincare/shared/ui';

import styles from './pet-tabs.module.css';

function PetTabs({ handleChange, tabsData, tabIndex }) {
  return (
    <Fragment>
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          <Tabs tabsData={tabsData} onChange={handleChange} activeValue={tabIndex} />
        </div>
      </div>
    </Fragment>
  );
}

export default PetTabs;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".direct-deposit-setup_subheadText_46f235a6 {\n  width: 220px;\n  margin: auto;\n  padding-top: 56px;\n}\n\n.direct-deposit-setup_bodyText_01630ba1 {\n  margin: 16px auto 24px;\n}\n\n.direct-deposit-setup_modalButtonsContainer_c25e0d0c {\n  display: flex;\n  flex-direction: column;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["upXs"] + " {\n  .direct-deposit-setup_modalContainer_46a60671 {\n    width: 480px;\n    height: 55%;\n  }\n\n  .direct-deposit-setup_subheadText_46f235a6 {\n    width: 100%;\n    padding-top: 42px;\n  }\n\n  .direct-deposit-setup_bodyText_01630ba1 {\n    margin-top: 32px;\n  }\n\n  .direct-deposit-setup_modalButtonsContainer_c25e0d0c {\n    flex-direction: row;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/user/view/direct-deposit-setup/delete-direct-deposit-modal.module.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":["@value upXs from '~@pumpkincare/shared/ui/breakpoints';\n\n.subheadText {\n  width: 220px;\n  margin: auto;\n  padding-top: 56px;\n}\n\n.bodyText {\n  margin: 16px auto 24px;\n}\n\n.modalButtonsContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n@media upXs {\n  .modalContainer {\n    width: 480px;\n    height: 55%;\n  }\n\n  .subheadText {\n    width: 100%;\n    padding-top: 42px;\n  }\n\n  .bodyText {\n    margin-top: 32px;\n  }\n\n  .modalButtonsContainer {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upXs": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["upXs"] + "",
	"subheadText": "direct-deposit-setup_subheadText_46f235a6",
	"bodyText": "direct-deposit-setup_bodyText_01630ba1",
	"modalButtonsContainer": "direct-deposit-setup_modalButtonsContainer_c25e0d0c",
	"modalContainer": "direct-deposit-setup_modalContainer_46a60671"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pet-tabs_tabsContainer_bae526c4 {\n  height: 40px;\n  background-color: var(--zeroWhite);\n  justify-content: center;\n  width: 100%;\n}\n\n.pet-tabs_tabs_fa0f300d {\n  justify-content: flex-start;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downSm"] + " {\n  .pet-tabs_tabsContainer_bae526c4 {\n    height: 68px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/claims/view/claims-pending/pet-tabs/pet-tabs.module.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,kCAAkC;EAClC,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["@value downSm from '~@pumpkincare/shared/ui/breakpoints';\n\n.tabsContainer {\n  height: 40px;\n  background-color: var(--zeroWhite);\n  justify-content: center;\n  width: 100%;\n}\n\n.tabs {\n  justify-content: flex-start;\n}\n\n@media downSm {\n  .tabsContainer {\n    height: 68px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downSm": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downSm"] + "",
	"tabsContainer": "pet-tabs_tabsContainer_bae526c4",
	"tabs": "pet-tabs_tabs_fa0f300d"
};
export default ___CSS_LOADER_EXPORT___;

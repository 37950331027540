import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Paths } from '../../../app-shell';
import { AccountBillingWrapper } from '../../../billing';
import getShouldDisplayLapsedBanner from '../../../billing/selectors/get-should-display-lapsed-banner';
import PaymentLapsedBanner from '../../../billing/view/payment-lapsed-banner';
import { AccountClaimsWrapper } from '../../../claims';
import ClinicsPage from '../clinics-page';
import MaintenanceModal from '../maintenance-modal';
import PetPolicy from '../pet-policy-wrapper';
import Profile from '../profile';

import styles from './member-center.module.css';

function MemberCenter() {
  const {
    allowMultiVetsPerPet,
    showScheduledMaintenanceFrame,
    showScheduledMaintenanceBanner,
  } = useFlags();

  const shouldDisplayLapsedBanner = useSelector(getShouldDisplayLapsedBanner);

  return showScheduledMaintenanceFrame &&
    Object.keys(showScheduledMaintenanceFrame).length > 0 ? (
    <Redirect
      to={{
        pathname: Paths.MaintenanceFrame,
        state: {
          timeStart: showScheduledMaintenanceFrame.start,
          timeEnd: showScheduledMaintenanceFrame.end,
        },
      }}
    />
  ) : (
    <div className={styles.root}>
      {showScheduledMaintenanceBanner &&
      Object.keys(showScheduledMaintenanceBanner).length > 0 ? (
        <MaintenanceModal
          timeStart={showScheduledMaintenanceBanner.start}
          timeEnd={showScheduledMaintenanceBanner.end}
        />
      ) : null}

      {shouldDisplayLapsedBanner ? <PaymentLapsedBanner /> : null}

      <div>
        <Switch>
          <Route path={Paths.Profile} component={Profile} />
          <Route path={Paths.Claims} component={AccountClaimsWrapper} />
          <Route path={Paths.Billing} component={AccountBillingWrapper} />
          <Route path={Paths.PetPolicy} component={PetPolicy} />
          <Route
            path={Paths.Clinics}
            component={() =>
              allowMultiVetsPerPet ? (
                <ClinicsPage />
              ) : (
                <Redirect to={Paths.Profile} />
              )
            }
          />

          <Redirect to={Paths.Profile} />
        </Switch>
      </div>
    </div>
  );
}

export default MemberCenter;

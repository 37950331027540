import { WWW_URL } from '@pumpkincare/config';
import {
  Body2,
  Checkbox,
  LegalBody,
  Link,
  SwitchInput,
  Typography,
} from '@pumpkincare/shared/ui';

import styles from './clinics-modal-body.module.css';

export default function ClinicsModalBody({
  userPets,
  petAssociations,
  onPetChange,
  claimsPermitted,
  toggleClaimsPermission,
}) {
  return (
    <>
      <Body2 className={styles.petsHeader}>Which pets visit this clinic?</Body2>

      {userPets.map(pet => (
        <Checkbox
          key={pet.id}
          value={pet.name}
          label={pet.name}
          onChange={() => onPetChange(pet.id)}
          checked={petAssociations[pet.id]}
          classes={{ root: styles.checkbox }}
        />
      ))}

      <div className={styles.permissions}>
        <h4>Permission to file claims:</h4>

        <SwitchInput
          checked={claimsPermitted}
          onChange={toggleClaimsPermission}
          id='canFileClaim'
        />
      </div>

      <LegalBody>
        By giving this clinic permission to file claims on my behalf, I confirm to
        the best of my knowledge that the statements they provide are true in every
        respect. I understand that the fees listed within a claim or invoice may not
        be covered or may exceed my plan benefits. I understand that I am financially
        responsible to my veterinarian for the entire treatment. I understand that
        claims cannot be adjusted without itemized receipts. I also understand that
        the deliberate misrepresentation of the animal’s condition or the omission of
        any material facts may result in the denial of a claim and/or the
        cancellation of coverage. I authorize United States Fire Insurance Company
        and its business partners to review and obtain a copy of ALL RECORDS
        including the insurance claim records and medical records as to examination,
        history, diagnosis, treatment and prognosis with respect to any condition. I
        further authorize these entities to disclose identifying information about me
        and my pet, as well as information about my claim experience, to my
        veterinarian. This permission and authorization shall remain effective for
        current and future claims filed on my behalf by vet clinics I have added to
        my online account; I can remove this permission and authorization at any time
        through my online account.
      </LegalBody>

      <LegalBody className={styles.privacy}>
        Information submitted is subject to our{' '}
        <Link
          href={`${WWW_URL}/privacy-center/privacy-policy`}
          target='_blank'
          rel='noreferrer'
          style={{ fontSize: 'inherit' }}
          className={Typography.legalBody}
        >
          Privacy Policy
        </Link>
      </LegalBody>
    </>
  );
}

import { formatCoverageStartDate } from '@pumpkincare/shared';

import { findInsuranceStartDate } from './find-insurance-start-date';

export function transformCoverageDates(pet) {
  if (!pet) {
    return {
      petName: null,
      insuranceStartDate: null,
    };
  }

  const insuranceStartDate = findInsuranceStartDate(pet.policies);

  return {
    petName: pet.name,
    insuranceStartDate: insuranceStartDate
      ? formatCoverageStartDate(insuranceStartDate)
      : null,
  };
}

import Routes from '../routes';

function App() {
  return (
    <div
      style={{
        margin: 'auto',
        backgroundColor: 'var(--zeroWhite)',
        fontFamily: 'var(--main-font)',
      }}
    >
      <Routes />
    </div>
  );
}

export default App;

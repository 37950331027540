import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Typography } from '@pumpkincare/shared/ui';

import { Divider } from '../../../user/view/shared';
import ClaimsHowToFile from '../claims-how-to-file';
import ClaimsPending from '../claims-pending';
import DirectDepositInfo from '../direct-deposit-info';
import TrackClaims from '../track-claims';

import styles from './account-claims-wrapper.module.css';

function AccountClaimsWrapper() {
  const { mgaClient } = useFlags();

  const headerCn = classnames(Typography.h1, styles.header);

  return (
    <div>
      <div className={styles.fileClaimContainer}>
        <h1 className={headerCn}>My Claims</h1>

        <ClaimsHowToFile />
      </div>

      {mgaClient ? (
        <TrackClaims />
      ) : (
        <div className={styles.paddings}>
          <Divider className={styles.divider1} />

          <ClaimsPending />

          <Divider className={styles.divider2} />
        </div>
      )}

      <div className={styles.directDepositContainer}>
        <DirectDepositInfo />
      </div>
    </div>
  );
}

export default AccountClaimsWrapper;

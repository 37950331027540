import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { isCreditCardExpDateValid, useBooleanInput } from '@pumpkincare/shared';
import { Body2, LegalBody } from '@pumpkincare/shared/ui';
import {
  getPaymentMethodBrand,
  getPaymentMethodExpMonth,
  getPaymentMethodExpYear,
  getPaymentMethodLast4,
  usePayment,
} from '@pumpkincare/user';

import { getSelf } from '../../../user';
import { getUserBillingAddress, getUserSelector } from '../../../user/selectors';
import CreditCardTypes from '../../constants/credit-card-types';
import EditPaymentModal from '../edit-payment-modal';

import styles from './payment-detail.module.css';

function PaymentDetail() {
  const dispatch = useDispatch();
  const billingAddress = useSelector(getUserBillingAddress);
  const user = useSelector(getUserSelector);

  const { data: paymentData } = usePayment();
  const expMonth = getPaymentMethodExpMonth(paymentData);
  const expYear = getPaymentMethodExpYear(paymentData);
  const brand = getPaymentMethodBrand(paymentData);
  const last4 = getPaymentMethodLast4(paymentData);

  const isCreditCardValid = isCreditCardExpDateValid(expMonth, expYear);

  const billingAddressText =
    billingAddress.street1 +
    ', ' +
    (billingAddress.street2 ? billingAddress.street2 + ', ' : '') +
    billingAddress.city +
    ', ' +
    billingAddress.stateProvince +
    ', ' +
    billingAddress.zipcode +
    ', United States';

  const [isModalOpen, toggleIsModalOpen] = useBooleanInput(
    useLocation().hash === '#edit-payment-method'
  );

  const imgSource = CreditCardTypes[brand];
  const imgAlt = brand + ' logo';

  function handleSuccessClose() {
    setTimeout(() => {
      dispatch(getSelf());
    }, 5000);

    toggleIsModalOpen();
  }

  return (
    <Fragment>
      <h4>Payment Method</h4>

      <div className={styles.container}>
        {!user.lapsedSince ? null : !isCreditCardValid ? (
          <LegalBody className={styles.expiredCreditCardError}>
            Your credit card has expired. Please update your payment method in order
            to process your past due payment.
          </LegalBody>
        ) : null}

        {billingAddress.zipcode ? (
          <div className={styles.card} data-testid='payment-method-card'>
            <div>
              {imgSource ? (
                <img src={imgSource} alt={imgAlt} className={styles.iconContainer} />
              ) : null}

              <div>
                <Body2>
                  {brand} ending in {last4}
                </Body2>

                <LegalBody
                  className={
                    user.lapsedSince && !isCreditCardValid
                      ? styles.expiredCreditCardDate
                      : ''
                  }
                >
                  {expMonth}/{expYear}
                </LegalBody>
              </div>

              <div>
                <LegalBody>
                  <span onClick={toggleIsModalOpen} className={styles.smView}>
                    Edit
                  </span>

                  <span onClick={toggleIsModalOpen} className={styles.lgView}>
                    Edit Payment Method
                  </span>
                </LegalBody>
              </div>
            </div>

            <div
              className={styles.billingAddressDiv}
              data-testid='billing-address-wrapper'
            >
              <Body2 className={styles.billingAddressTitle}>Billing Address</Body2>

              <LegalBody data-testid='billing-address'>
                {billingAddressText}
              </LegalBody>
            </div>
          </div>
        ) : null}
      </div>

      {isModalOpen ? (
        <EditPaymentModal
          handleCancel={toggleIsModalOpen}
          handleSuccessClose={handleSuccessClose}
        />
      ) : null}
    </Fragment>
  );
}

export default PaymentDetail;

import { setTrackedCustomProps } from '@pumpkincare/analytics';

import { setUserEmail } from '../state/user-ducks';

export default function changeEmail(newEmail) {
  return (dispatch, getState, { userService }) => {
    return userService.changeEmail(newEmail).then(() => {
      setTrackedCustomProps({ email: newEmail });

      dispatch(setUserEmail(newEmail));
    });
  };
}

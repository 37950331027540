import { diffSince } from '@pumpkincare/shared';

export function calculateAmountDue(invoices = []) {
  const amount = invoices
    .filter(({ status }) => status !== 'paid')
    .reduce((acc, { total }) => acc + total, 0);
  return amount / 100;
}

export function calculateLapsedSince(lapsedSince) {
  return lapsedSince ? diffSince(lapsedSince, 'days') : 0;
}

export function formatAmountDue(amount) {
  return `$${parseFloat(amount).toFixed(2)}`;
}

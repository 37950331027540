import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import { setTrackedCustomProps } from '@pumpkincare/analytics';
import { QF_URL } from '@pumpkincare/config';
import { getIsLoggedIn } from '@pumpkincare/shared';
import {
  ButtonStyles,
  LegalBody,
  Link,
  LoaderButton,
  RouteLink,
  TextField,
} from '@pumpkincare/shared/ui';
import { useLogIn } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import { setUserSelf } from '../../state';

import styles from './login-page.module.css';

function LoginPage() {
  const dispatch = useDispatch();

  const logIn = useLogIn();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const errMsg = 'Email or password not recognized';

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    logIn(login, password) // returns /self endpoint response
      .then(response => {
        setIsLoading(false);

        setTrackedCustomProps({
          userId: response.users.id,
          userPets: response.users.pets,
          email: response.users.email,
        });

        dispatch(setUserSelf(response)); // will go away eventually
        dispatch(push(Paths.Profile));
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage(errMsg);
      });
  }

  return getIsLoggedIn() ? (
    <Redirect to={Paths.Profile} />
  ) : (
    <div className={styles.root}>
      <div className={styles.content}>
        <h3 className={styles.loginHeader}>Pumpkin Login</h3>

        <form onSubmit={handleSubmit} noValidate>
          <TextField
            id='email'
            value={login}
            label='Please Enter Email'
            onChange={e => setLogin(e.target.value)}
          />
          <TextField
            id='password'
            value={password}
            label='Password'
            type='password'
            onChange={e => setPassword(e.target.value)}
          />
          <LegalBody isBold className={styles.errorText}>
            {errorMessage}
          </LegalBody>
          <LoaderButton
            color='primary'
            data-testid='submit-button'
            classes={{ root: styles.submitButton }}
            isLoading={isLoading}
            disabled={!login || !password}
            type='submit'
          >
            Submit
          </LoaderButton>
        </form>

        <div className={styles.dontKnowPassword}>
          <RouteLink to={Paths.ForgotPassword} className={styles.link}>
            {`I don't know my password`}
          </RouteLink>
        </div>

        <div className={styles.newHere}>
          <h4>
            New here?
            <br />
            Start by getting a quote!
          </h4>
        </div>

        <Link href={QF_URL}>
          <button
            color='primary'
            className={classNames(ButtonStyles.primary, styles.createPlanButton)}
          >
            Create Plan
          </button>
        </Link>
      </div>
    </div>
  );
}
export default LoginPage;

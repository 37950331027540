// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".member-center_root_e5c27766 {\n  background: var(--zeroWhite);\n  background-color: var(--zeroWhite);\n}\n", "",{"version":3,"sources":["webpack://src/user/view/member-center/member-center.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,kCAAkC;AACpC","sourcesContent":[".root {\n  background: var(--zeroWhite);\n  background-color: var(--zeroWhite);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "member-center_root_e5c27766"
};
export default ___CSS_LOADER_EXPORT___;

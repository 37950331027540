import { LegalBody } from '@pumpkincare/shared/ui';

import styles from './prevent-plan-description.module.css';

export default function PreventPlanDescription({ planItems = [] }) {
  return planItems.map((planItem, index) => {
    return (
      <div className={styles.preventItem} key={index}>
        <LegalBody className={styles.preventTitle}>{planItem.item}</LegalBody>

        {planItem.subtext ? (
          <LegalBody className={styles.preventSubText}>
            <span dangerouslySetInnerHTML={{ __html: planItem.subtext }}></span>
          </LegalBody>
        ) : null}
      </div>
    );
  });
}

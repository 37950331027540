const Paths = {
  Error: '/error',
  Maintenance: '/maintenance',
  MaintenanceFrame: '/maintenance-frame',

  Account: '/account/:component',
  AccountVetForm: '/account/pet/:petId/vet-form',
  Billing: '/account/billing',
  Claims: '/account/claims',
  PetPolicy: '/account/pet-policy',
  Profile: '/account/profile',
  Clinics: '/account/clinics',

  ClaimsSubmission: '/claim-submission',
  ClaimsSubmissionSuccess: '/claim-submission-success',
  DirectDeposit: '/direct-deposit',
  DirectDepositSuccess: '/direct-deposit-success',
  ForgotPassword: '/forgot-password',
  Login: '/login',
  Registration: '/register',
};

export default Paths;

import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getDateRangeLabel } from '@pumpkincare/shared';

import { getUserPetsSelector } from '../../../user/selectors/user-selectors';
import { buildDateRangeLabel, sortClaims } from '../../../user/utils/claims-utils';
import { getActivePolicy } from '../../../user/utils/policy-utils';
import { CLAIM_TYPE_PREVENT, CLAIM_TYPE_PROTECT } from '../../constants';
import ClaimCard from './claim-card';
import PetTabs from './pet-tabs';

import styles from './claims-pending.module.css';

function ClaimsPending() {
  const pets = useSelector(getUserPetsSelector);

  const [tabIndex, setTabIndex] = useState(0);
  const [tabsData, setTabsData] = useState([]);

  useEffect(() => {
    let tabsDataRaw = [];
    let initialSelectedPolicy;

    pets.forEach(pet => {
      if (pet.claims && pet.claims.length > 0) {
        initialSelectedPolicy = getActivePolicy(pet.policies) || pet.policies[0];

        tabsDataRaw.push({
          label: pet.name,
          claims: pet.claims,
          claimsFiltered: sortClaims(pet.claims),
          policiesDateRanges: buildDateRangeLabel(pet.policies),
          selectedPolicy: initialSelectedPolicy,
          selectedLabel: getDateRangeLabel(initialSelectedPolicy),
        });
      }
    });
    setTabsData(tabsDataRaw);
  }, [pets]);

  return (
    <Fragment>
      <h3 className={styles.sectionTitle}>{`Track Claims`}</h3>
      <div className={styles.root}>
        {tabsData.length > 0 ? (
          <PetTabs
            tabIndex={tabIndex}
            handleChange={tab => setTabIndex(tab)}
            tabsData={tabsData}
          />
        ) : null}

        <div className={styles.claimsContainer}>
          {tabsData?.length > 0 &&
            tabsData.map((tabData, idx) => {
              return tabIndex === idx
                ? tabData.claimsFiltered.map(claimAux => {
                    if (claimAux.claim_type === 'Both') {
                      return (
                        <Fragment key={claimAux.id}>
                          <ClaimCard
                            type={CLAIM_TYPE_PREVENT}
                            status={
                              claimAux.prevent_claim
                                ? claimAux.prevent_claim.status
                                : claimAux.status
                            }
                            claim={
                              claimAux.prevent_claim
                                ? claimAux.prevent_claim
                                : claimAux
                            }
                            documentUrl={
                              claimAux.prevent_claim
                                ? claimAux.prevent_claim.summary_download_url
                                : ''
                            }
                            claimId={claimAux.claim_number + ' - 000000'}
                            amountPaid={
                              claimAux.prevent_claim &&
                              claimAux.prevent_claim.reimbursement_amount
                                ? claimAux.prevent_claim.reimbursement_amount
                                : null
                            }
                            amountClaimed={
                              claimAux.prevent_claim &&
                              claimAux.prevent_claim.amount_claimed
                                ? claimAux.prevent_claim.amount_claimed
                                : null
                            }
                            submissionDate={claimAux.date_submitted}
                            sendReimbursementToVet={
                              claimAux.send_reimbursement_to_vet
                            }
                            submittedBy={claimAux.submitted_by?.display_name}
                          />
                          <ClaimCard
                            type={CLAIM_TYPE_PROTECT}
                            status={
                              claimAux.protect_claim
                                ? claimAux.protect_claim.status
                                : claimAux.status
                            }
                            claim={
                              claimAux.protect_claim
                                ? claimAux.protect_claim
                                : claimAux
                            }
                            documentUrl={
                              claimAux.protect_claim
                                ? claimAux.protect_claim.eob_download_url
                                : ''
                            }
                            claimId={
                              claimAux.protect_claim &&
                              claimAux.protect_claim.external_claim_id
                                ? claimAux.claim_number +
                                  ' - ' +
                                  claimAux.protect_claim.external_claim_id
                                : claimAux.claim_number
                            }
                            amountPaid={
                              claimAux.protect_claim &&
                              claimAux.protect_claim.reimbursement_amount
                                ? claimAux.protect_claim.reimbursement_amount
                                : null
                            }
                            amountClaimed={
                              claimAux.protect_claim &&
                              claimAux.protect_claim.amount_claimed
                                ? claimAux.protect_claim.amount_claimed
                                : null
                            }
                            submissionDate={claimAux.date_submitted}
                            sendReimbursementToVet={
                              claimAux.send_reimbursement_to_vet
                            }
                            submittedBy={claimAux.submitted_by?.display_name}
                          />
                        </Fragment>
                      );
                    } else {
                      let claimNumber;
                      let claimType;
                      let documentUrl;
                      let claim;
                      if (claimAux.claim_type === 'Prevent') {
                        claimType = CLAIM_TYPE_PREVENT;
                        claimNumber = claimAux.claim_number + ' - 000000';
                        documentUrl = claimAux.prevent_claim
                          ? claimAux.prevent_claim.summary_download_url
                          : '';
                        claim = claimAux.prevent_claim
                          ? claimAux.prevent_claim
                          : claimAux;
                      } else {
                        claimType = claimAux.claim_type;
                        claimNumber =
                          claimAux.protect_claim &&
                          claimAux.protect_claim.external_claim_id
                            ? claimAux.claim_number +
                              ' - ' +
                              claimAux.protect_claim.external_claim_id
                            : claimAux.claim_number;
                        documentUrl = claimAux.protect_claim
                          ? claimAux.protect_claim.eob_download_url
                          : '';
                        claim = claimAux.protect_claim
                          ? claimAux.protect_claim
                          : claimAux;
                      }

                      return (
                        <ClaimCard
                          type={claimType}
                          status={claimAux.status}
                          key={claimAux.id}
                          claim={claim}
                          documentUrl={documentUrl}
                          claimId={claimNumber}
                          amountPaid={claimAux.amount_paid}
                          amountClaimed={claimAux.amount_claimed}
                          submissionDate={claimAux.date_submitted}
                          sendReimbursementToVet={claimAux.send_reimbursement_to_vet}
                          submittedBy={claimAux.submitted_by?.display_name}
                        />
                      );
                    }
                  })
                : null;
            })}
        </div>
      </div>
    </Fragment>
  );
}

export default ClaimsPending;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paperless-callout_root_edc64c23 {\n  background: var(--wishboneGreen);\n  border-radius: 8px;\n  color: var(--scoutNavy);\n  padding: 24px 40px 24px 32px;\n}\n\n.paperless-callout_header_dcf8ca1e {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.paperless-callout_header_dcf8ca1e img {\n  width: 32px;\n}\n\n.paperless-callout_copy_c44026e5 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin: 8px 0 24px;\n}\n\n.paperless-callout_copy_c44026e5 ul {\n  margin: 16px 0;\n  padding-left: 24px;\n}\n\n.paperless-callout_cta_d635314d {\n  width: 100%;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n  .paperless-callout_cta_d635314d {\n    width: 280px;\n  }\n}", "",{"version":3,"sources":["webpack://src/user/view/paperless-callout/paperless-callout.css"],"names":[],"mappings":"AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n  background: var(--wishboneGreen);\n  border-radius: 8px;\n  color: var(--scoutNavy);\n  padding: 24px 40px 24px 32px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.header img {\n  width: 32px;\n}\n\n.copy {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin: 8px 0 24px;\n}\n\n.copy ul {\n  margin: 16px 0;\n  padding-left: 24px;\n}\n\n.cta {\n  width: 100%;\n}\n\n@media tablet {\n  .cta {\n    width: 280px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"root": "paperless-callout_root_edc64c23",
	"header": "paperless-callout_header_dcf8ca1e",
	"copy": "paperless-callout_copy_c44026e5",
	"cta": "paperless-callout_cta_d635314d"
};
export default ___CSS_LOADER_EXPORT___;

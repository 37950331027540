import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { useFileClaim } from '@pumpkincare/claims';
import { GENERIC_ERROR, useBanners } from '@pumpkincare/shared';
import { getUserPets, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';

function useSubmitClaim() {
  const dispatch = useDispatch();

  const { addBanner, removeAllBanners } = useBanners();

  const { mutateAsync: fileClaim, status } = useFileClaim();
  const { data: userPetsData } = useUssr(getUserPets);

  function submit(claim) {
    removeAllBanners();

    return fileClaim(claim)
      .then(response => {
        const { name } = userPetsData.find(pet => pet.id === response.pet_id);

        dispatch(
          push(
            `${Paths.ClaimsSubmissionSuccess}?petName=${name}&isPrevent=${response.is_prevent}`
          )
        );
      })
      .catch(err => {
        addBanner(GENERIC_ERROR);

        throw err;
      });
  }

  return { status, submit };
}

export default useSubmitClaim;

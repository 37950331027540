// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".prevent-plan-description_preventItem_b2942ce8 {\n    width: 100%;\n}\n\n.prevent-plan-description_preventItem_b2942ce8 .prevent-plan-description_preventTitle_749675a7 {\n    width: 100%;\n    margin-top: 8px;\n}\n  \n.prevent-plan-description_preventItem_b2942ce8 .prevent-plan-description_preventSubText_a500ffc2 {\n    margin-bottom: 16px;\n    max-width: 250px;\n    float: right;\n}", "",{"version":3,"sources":["webpack://src/user/view/prevent-plan-description/prevent-plan-description.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".preventItem {\n    width: 100%;\n}\n\n.preventItem .preventTitle {\n    width: 100%;\n    margin-top: 8px;\n}\n  \n.preventItem .preventSubText {\n    margin-bottom: 16px;\n    max-width: 250px;\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preventItem": "prevent-plan-description_preventItem_b2942ce8",
	"preventTitle": "prevent-plan-description_preventTitle_749675a7",
	"preventSubText": "prevent-plan-description_preventSubText_a500ffc2"
};
export default ___CSS_LOADER_EXPORT___;

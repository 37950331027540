import {
  validateDateMMDDYYY,
  validateDateMMDDYYYNotBefore,
} from '@pumpkincare/shared';

export default function validateClaimsDate(
  dateStr,
  dateLabel,
  insuranceStartDateStr
) {
  const INVALID_DATE_FORMAT = `Please enter a valid date format (MM/DD/YYYY)`;
  const DATE_IS_OUTSIDE_INSURANCE = `${dateLabel} is outside your Insurance coverage date`;

  if (validateDateMMDDYYY(dateStr) === false) {
    return INVALID_DATE_FORMAT;
  }

  if (validateDateMMDDYYYNotBefore(dateStr, insuranceStartDateStr) === false) {
    return DATE_IS_OUTSIDE_INSURANCE;
  }

  return true;
}

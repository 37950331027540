import _ from 'lodash';

import { dateIsToday, formatPolicyDates } from '@pumpkincare/shared';

import { getHasActivePolicy, getHasInactivePolicy } from './policy-utils';

export function formatPetAge(age) {
  const years = Number(age);

  if (Number.isNaN(years)) {
    return `Under 12 months`;
  } else if (years === 1) {
    return `${years} year`;
  } else {
    return `${years} years`;
  }
}

const numbers = /\d+/;

export function transformPetPolicy(pet) {
  const policy =
    pet && pet.policies
      ? pet.policies.find(policy => getHasActivePolicy(policy))
      : null;

  const renewalPlan = pet.plans.find(plan => plan.status === 'renew_pending');

  const plan = pet.plans ? pet.plans.find(plan => getHasActivePolicy(plan)) : null;

  const planVersion = plan?.plan?.version_number;

  const renewalPolicy =
    pet && pet.policies
      ? pet.policies.find(policy => policy.status === 'renew_pending')
      : null;

  const renewalPolicyPrice = renewalPolicy
    ? parseFloat(renewalPolicy.premium)
    : null;

  const formattedRenewalPolicyDates = renewalPolicy
    ? formatPolicyDates(
        renewalPolicy.policy_effective_date,
        renewalPolicy.policy_end_date,
        renewalPolicy.policy_illness_effective_date
      )
    : null;

  const renewalPolicyStartDate = formattedRenewalPolicyDates?.startDate ?? null;
  const renewalPolicyEndDate = formattedRenewalPolicyDates?.endDate ?? null;

  const isRenewalDay = policy
    ? dateIsToday(policy.policy_effective_date) &&
      dateIsToday(policy.policy_illness_effective_date)
    : false;

  const policyRenewalAnnualDeductible = renewalPolicy
    ? parseInt(renewalPolicy.deductible.match(numbers))
    : null;

  const policyRenewalAnnualReimbursement = renewalPolicy
    ? `${100 - parseInt(renewalPolicy.coinsurance.match(numbers))}%`
    : null;

  const policyRenewalAnnualLimit = renewalPolicy
    ? renewalPolicy.annual_limit.replace('Limit', '')
    : null;

  const renewalPlanPrice =
    renewalPlan && renewalPlan.plan ? parseFloat(renewalPlan.plan.cost) : null;

  const formattedPolicyDates = policy
    ? formatPolicyDates(
        policy.policy_effective_date,
        policy.policy_end_date,
        policy.policy_illness_effective_date
      )
    : null;

  const policyStartDate = formattedPolicyDates?.startDate ?? null;
  const policyEndDate = formattedPolicyDates?.endDate ?? null;
  const policyEffectiveDate = formattedPolicyDates?.illnessEffectiveDate ?? null;

  const policyAnnualDeductible = policy
    ? parseInt(policy.deductible.match(numbers))
    : null;

  const policyAnnualReimbursement = policy
    ? `${100 - parseInt(policy.coinsurance.match(numbers))}%`
    : null;

  const policyAnnualLimit = policy ? policy.annual_limit.replace('Limit', '') : null;

  return {
    renewalPolicyPrice,
    renewalPolicyStartDate,
    renewalPolicyEndDate,
    renewalPlanPrice,
    policyStartDate,
    policyEndDate,
    policyEffectiveDate,
    policyAnnualDeductible,
    policyAnnualReimbursement,
    policyAnnualLimit,
    policyRenewalAnnualReimbursement,
    policyRenewalAnnualDeductible,
    policyRenewalAnnualLimit,
    renewalPlan,
    renewalPolicy,
    policy,
    isRenewalDay,
    planVersion,
  };
}

export function transformPolicy(pet, policy) {
  const userPlan = pet.plans
    ? pet.plans.find(plan => getHasInactivePolicy(plan))
    : null;
  const planName = userPlan ? 'Insurance + Preventive' : 'Insurance';
  const policyStatus = policy ? _.capitalize(policy.status) : null;
  const policyPrice = policy
    ? '$' + parseFloat(policy.premium).toFixed(2) + ' / mo'
    : null;
  const planDescription = userPlan && userPlan.plan ? userPlan.plan.description : '';

  const formattedPolicyDates = formatPolicyDates(
    policy.policy_effective_date,
    policy.policy_end_date,
    policy.policy_illness_effective_date
  );

  const period =
    formattedPolicyDates.startDate + ' - ' + formattedPolicyDates.endDate;

  return {
    id: policy.id,
    petName: pet.name,
    species: pet.species,
    userPlan,
    planName,
    policyStatus,
    policyPrice,
    period,
    policy_s3_path: policy.policy_s3_path,
    planDescription,
  };
}

export const VISA = 'Visa';
export const AMERICAN_EXPRESS = 'American Express';
export const DINERS_CLUB = 'Diners Club';
export const DISCOVER = 'Discover';
export const JCB = 'JCB';
export const MASTERCARD = 'MasterCard';

const CreditCardTypes = {
  [VISA]: '/assets/images/CCBrands/Visa.png',
  [AMERICAN_EXPRESS]: '/assets/images/CCBrands/Amex.png',
  [DINERS_CLUB]: '/assets/images/CCBrands/DinersClub.png',
  [DISCOVER]: '/assets/images/CCBrands/Discover.png',
  [JCB]: '/assets/images/CCBrands/JCB.png',
  [MASTERCARD]: '/assets/images/CCBrands/Mastercard.png',
};

export default CreditCardTypes;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pet-medical-history-upload_saveButton_e57466c9 {\n  width: 100%;\n  margin-top: 32px;\n}\n\n.pet-medical-history-upload_contactInfo_e400ac24 {\n  margin-top: 24px;\n}\n\n.pet-medical-history-upload_contactInfoText_184b8095 {\n  color:  var(--gromitGray);\n  margin-bottom: 24px;\n}\n\n.pet-medical-history-upload_subHeaderText_9dc2cabe {\n  margin-top: 24px;\n  margin-bottom: 28px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n  .pet-medical-history-upload_subheadText_370365ef {\n    width: 100%;\n    margin-top: 32px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/user/view/profile/my-pet-information/pet-medical-history-upload/pet-medical-history-upload.module.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":["@value b-desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.saveButton {\n  width: 100%;\n  margin-top: 32px;\n}\n\n.contactInfo {\n  margin-top: 24px;\n}\n\n.contactInfoText {\n  color:  var(--gromitGray);\n  margin-bottom: 24px;\n}\n\n.subHeaderText {\n  margin-top: 24px;\n  margin-bottom: 28px;\n}\n\n@media b-desktop {\n  .subheadText {\n    width: 100%;\n    margin-top: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"saveButton": "pet-medical-history-upload_saveButton_e57466c9",
	"contactInfo": "pet-medical-history-upload_contactInfo_e400ac24",
	"contactInfoText": "pet-medical-history-upload_contactInfoText_184b8095",
	"subHeaderText": "pet-medical-history-upload_subHeaderText_9dc2cabe",
	"subheadText": "pet-medical-history-upload_subheadText_370365ef"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".clinics-modal-body_petsHeader_16e1d706 {\n    margin: 20px 0;\n}\n\n.clinics-modal-body_petsHeader_16e1d706:after {\n    content: '*';\n    color: var(--crookshanksRed);\n}\n\n.clinics-modal-body_checkbox_96b11832 {\n    margin: 16px 0;\n    display: flex;\n}\n\n.clinics-modal-body_permissions_9c9086f2 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\ndiv.clinics-modal-body_privacy_8cac7f4f {\n    margin-top: 16px;\n}", "",{"version":3,"sources":["webpack://src/user/view/clinics-modal-body/clinics-modal-body.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".petsHeader {\n    margin: 20px 0;\n}\n\n.petsHeader:after {\n    content: '*';\n    color: var(--crookshanksRed);\n}\n\n.checkbox {\n    margin: 16px 0;\n    display: flex;\n}\n\n.permissions {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\ndiv.privacy {\n    margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"petsHeader": "clinics-modal-body_petsHeader_16e1d706",
	"checkbox": "clinics-modal-body_checkbox_96b11832",
	"permissions": "clinics-modal-body_permissions_9c9086f2",
	"privacy": "clinics-modal-body_privacy_8cac7f4f"
};
export default ___CSS_LOADER_EXPORT___;

import { useState } from 'react';
import classNames from 'classnames';

import {
  ARF_MEOW_PHONE_NUMBER,
  FORMATTED_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { useBooleanInput } from '@pumpkincare/shared';
import { LegalBody, Link, Spinner, Typography } from '@pumpkincare/shared/ui';
import { useMonthlyStatements } from '@pumpkincare/user';

import ChargeGroup from '../charge-group/charge-group';
import ChargeGroupStatementsModal from '../charge-group-statements-modal';

import styles from './monthly-statements.module.css';

function MonthlyStatements() {
  const { data: monthlyStatementsData, isFetching } = useMonthlyStatements();

  const [isModalOpen, toggleIsModalOpen] = useBooleanInput(false);
  const [modalData, setModalData] = useState(null);

  function handleViewMonthlyStatementsClick(data) {
    setModalData(data);
    toggleIsModalOpen();
  }

  function createCard(statement) {
    return (
      <ChargeGroup
        key={statement.monthly_charge_day}
        data={statement}
        onViewMonthlyStatementsClick={() =>
          handleViewMonthlyStatementsClick(statement)
        }
      />
    );
  }

  return (
    <div className={styles.root}>
      <h4>Monthly Statements</h4>

      <LegalBody>
        Below is a summary of current balance and access to your previous invoices.
        <div className={styles.billingQuestion}>
          Have a billing question? Call our Pumpkin Care Team at{' '}
          <Link
            href={PHONE_NUMBER_LINK}
            className={classNames(Typography.legalBody, styles.phone)}
          >
            {`${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER})`}
          </Link>
        </div>
      </LegalBody>

      {isFetching ? (
        <Spinner role={'progressbar'} />
      ) : (
        <div className={styles.cards}>{monthlyStatementsData.map(createCard)}</div>
      )}

      {isModalOpen && (
        <ChargeGroupStatementsModal
          handleCancel={toggleIsModalOpen}
          data={modalData}
        />
      )}
    </div>
  );
}

export default MonthlyStatements;

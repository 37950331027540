import { useLocation } from 'react-router';
import queryString from 'query-string';

import { Body1, Body2, RouteLink, SimpleHeader } from '@pumpkincare/shared/ui';

import { Paths } from '../../../app-shell';

import styles from './claims-submission-success.module.css';

function ClaimsSubmissionSuccess() {
  const { search } = useLocation();
  const { petName, isPrevent } = queryString.parse(search);

  return (
    <>
      <div className={styles.logoWrapper}>
        <SimpleHeader returnLink={Paths.Claims} />
      </div>

      <div className={styles.container}>
        <div className={styles.leftColumn}>
          <h2 className={styles.header}>Thank you for submitting your claim.</h2>

          <Body2 className={styles.textSubHead}>
            {isPrevent === 'true'
              ? 'And kudos for being such a boss pet parent!'
              : `We hope ${petName} feels better soon!`}
          </Body2>

          <Body2>What happens next?</Body2>

          <div className={styles.infoDiv}>
            <ul className={styles.infoList}>
              <li>
                <Body1>
                  Look for a confirmation email for your claim submission – this will
                  include your Claim ID
                </Body1>
              </li>

              <li>
                <Body1>Track your claim status through your account anytime</Body1>
              </li>

              <li>
                <Body1>
                  {`Get reimbursed via check or direct deposit once it's approved`}
                </Body1>
              </li>
            </ul>
          </div>

          <Body2 className={styles.directDepositInfo}>
            Want to get paid back faster?
          </Body2>

          <div className={styles.directDepositDiv}>
            <RouteLink to={Paths.DirectDeposit}>
              <Body2 isBold className={styles.directDepositLink}>
                Set up Direct Deposit today.
              </Body2>
            </RouteLink>
          </div>
        </div>

        <div className={styles.rightColumn}>
          <div className={styles.divImage}>
            <img
              className={
                isPrevent === 'true' ? styles.petImage : styles.dogAccidentImage
              }
              src={
                isPrevent === 'true'
                  ? '/assets/images/quote-flow/life-style-interstitial/dog-homebody.png'
                  : '/assets/images/quote-flow/worry-interstitial/dog-accidents-and-injuries.png'
              }
              alt='pet'
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimsSubmissionSuccess;

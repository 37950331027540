import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';
import { transformMonthlyStatement } from '@pumpkincare/user';

import styles from './charge-group.module.css';

function ChargeGroup(props) {
  const { data, onViewMonthlyStatementsClick } = props;

  const chargeGroup = transformMonthlyStatement(data);

  const rootClassName = classNames(styles.root, {
    [styles.rootLapsed]: chargeGroup.isLapsed,
  });

  const balanceClassName = classNames({
    [styles.balanceLapsed]: chargeGroup.isLapsed,
  });

  const balanceLabelClassName = classNames(styles.balanceLabel, {
    [styles.balanceLabelLapsed]: chargeGroup.isLapsed,
  });

  return (
    <div className={rootClassName}>
      <div className={styles.leftContainer}>
        <LegalBody isBold>{chargeGroup.petNames}</LegalBody>

        {chargeGroup.nextChargeDate ? (
          <LegalBody className={styles.nextPayment}>
            Next payment scheduled on {chargeGroup.nextChargeDate}
          </LegalBody>
        ) : null}

        <button
          className={classNames(Typography.legalBody, styles.button)}
          role='button'
          onClick={onViewMonthlyStatementsClick}
        >
          View Monthly Statements
        </button>
      </div>

      <div className={styles.rightContainer}>
        <Body2 className={balanceClassName}>${chargeGroup.balance}</Body2>

        <LegalBody className={balanceLabelClassName}>
          {chargeGroup.textStatus}
        </LegalBody>
      </div>
    </div>
  );
}

export default ChargeGroup;

ChargeGroup.propTypes = {
  data: PropTypes.object.isRequired,
  onViewMonthlyStatementsClick: PropTypes.func.isRequired,
};

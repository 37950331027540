import MonthlyStatements from '../../../monthly-statements/view/monthly-statements';
import { Divider, Header } from '../../../user/view/shared';
import PaymentDetail from '../payment-detail';

import styles from './account-billing-wrapper.module.css';

function AccountBillingWrapper() {
  return (
    <div className={styles.billingWrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header>Billing Information</Header>
        </div>

        <PaymentDetail />
        <Divider className={styles.accountDivider} />
        <MonthlyStatements />
      </div>
    </div>
  );
}

export default AccountBillingWrapper;

import { Redirect, Route } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getIsLoggedIn } from '@pumpkincare/shared';
import {
  ErrorPage,
  MaintenanceFrame,
  MaintenancePage,
} from '@pumpkincare/shared/pages';

import { ClaimsSubmission, ClaimsSubmissionSuccess } from '../claims';
import {
  DirectDepositSetupPage,
  DirectDepositSuccess,
  ForgotPassword,
  LoginPage,
  MemberCenter,
  Registration,
} from '../user';
import Page from './view/page';
import PrivatePage from './view/private-page';
import Paths from './paths';

function Routes() {
  const { outageMessage } = useFlags();
  const isLoggedIn = getIsLoggedIn();

  return (
    <>
      {outageMessage ? <Redirect from={Paths.Login} to={Paths.Maintenance} /> : null}

      <Route path={'/'} exact>
        {isLoggedIn ? (
          <Redirect to={Paths.Account} />
        ) : (
          <Redirect to={Paths.Login} />
        )}
      </Route>

      <Route path={Paths.Error}>
        <Page>
          <ErrorPage />
        </Page>
      </Route>

      <Route path={Paths.Maintenance}>
        <Page>
          <MaintenancePage />
        </Page>
      </Route>

      <Route path={Paths.MaintenanceFrame}>
        <Page isNavBarVisible={false} isFooterVisible={false}>
          <MaintenanceFrame />
        </Page>
      </Route>

      <Route path={Paths.Login}>
        <Page>
          <LoginPage />
        </Page>
      </Route>

      <Route path={Paths.ForgotPassword}>
        <Page>
          <ForgotPassword />
        </Page>
      </Route>

      <Route path={Paths.Registration}>
        <Page>
          <Registration />
        </Page>
      </Route>

      <Route path={Paths.DirectDeposit}>
        <PrivatePage isNavBarVisible={false}>
          <DirectDepositSetupPage />
        </PrivatePage>
      </Route>

      <Route path={Paths.DirectDepositSuccess}>
        <PrivatePage isNavBarVisible={false}>
          <DirectDepositSuccess />
        </PrivatePage>
      </Route>

      <Route path={Paths.ClaimsSubmission}>
        <PrivatePage isNavBarVisible={false}>
          <ClaimsSubmission />
        </PrivatePage>
      </Route>

      <Route path={Paths.ClaimsSubmissionSuccess}>
        <PrivatePage isNavBarVisible={false}>
          <ClaimsSubmissionSuccess />
        </PrivatePage>
      </Route>

      <Route path={Paths.Account}>
        <PrivatePage>
          <MemberCenter />
        </PrivatePage>
      </Route>
    </>
  );
}

export default Routes;

import { setTrackedCustomProps } from '@pumpkincare/analytics';
import { getUserSelf } from '@pumpkincare/user';

import { setUserSelf } from '../state';

export default function getSelf() {
  return dispatch => {
    return getUserSelf().then(response => {
      setTrackedCustomProps({
        userId: response.users.id,
        userPets: response.users.pets,
        email: response.users.email,
      });

      dispatch(setUserSelf(response));
    });
  };
}

export const billingConfig = billingAddress => ({
  firstName: {
    id: 'firstName',
    label: 'First Name',
    value: billingAddress.firstName || '',
  },
  lastName: {
    id: 'lastName',
    label: 'Last Name',
    value: billingAddress.lastName || '',
  },
  address1: {
    id: 'address1',
    label: 'Address',
    value: billingAddress.street1 || '',
  },
  address2: {
    id: 'address2',
    label: 'Address Continued',
    value: billingAddress.street2 || '--',
  },
  zipcode: {
    id: 'zipcode',
    label: 'Zip Code',
    value: billingAddress.zipcode || '',
  },
  state: {
    id: 'state',
    label: 'State',
    value: billingAddress.stateProvince || '',
  },
  city: {
    id: 'city',
    label: 'City',
    value: billingAddress.city || '',
  },
});

export const shippingConfig = (shippingAddress, firstName, lastName) => ({
  firstName: {
    id: 'firstName',
    label: 'First Name',
    value: firstName || '',
  },
  lastName: {
    id: 'lastName',
    label: 'Last Name',
    value: lastName || '',
  },
  address1: {
    id: 'address1',
    label: 'Address',
    value: shippingAddress.street1 || '',
  },
  address2: {
    id: 'address2',
    label: 'Address Continued',
    value: shippingAddress.street2 || '--',
  },
  zipcode: {
    id: 'zipcode',
    label: 'Zip Code',
    value: shippingAddress.zipcode || '',
  },
  state: {
    id: 'state',
    label: 'State',
    value: shippingAddress.stateProvince || '',
  },
  city: {
    id: 'city',
    label: 'City',
    value: shippingAddress.city || '',
  },
});

export const userConfig = user => ({
  phone: {
    id: 'phone',
    label: 'Phone Number',
    value: user.phone || '',
  },
  email: {
    id: 'email',
    label: 'Email Address',
    value: user.email || '',
  },
});

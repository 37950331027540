import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './pagination.module.css';
function Pagination(props) {
  const { range, count, currentPage, onPageChange } = props;

  const { from, to } = range;

  const prevClassName = classnames(styles.prevIcon, {
    [styles.disabled]: from <= 1,
  });

  const nextClassName = classnames(styles.nextIcon, {
    [styles.disabled]: to + 1 >= count,
  });

  function handlePrevClick() {
    onPageChange(currentPage - 1);
  }

  function handleNextClick() {
    onPageChange(currentPage + 1);
  }

  return (
    <div className={styles.container}>
      <span>{`${from}-${to} of ${count}`}</span>

      <div className={styles.buttonWrapper}>
        <button onClick={handlePrevClick} disabled={from <= 1}>
          <img
            className={prevClassName}
            src='/assets/images/iconImages/arrow-right.svg'
          />
        </button>
        <button onClick={handleNextClick} disabled={to + 1 >= count}>
          <img
            className={nextClassName}
            src='/assets/images/iconImages/arrow-right.svg'
          />
        </button>
      </div>
    </div>
  );
}

Pagination.defaultProps = {
  onPageChange: () => {},
  range: { from: 1, to: 1 },
  count: 1,
  currentPage: 1,
};

Pagination.propTypes = {
  range: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }),
  count: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};
export default Pagination;

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import {
  CHECKING,
  GENERIC_CONTACT_ERROR,
  GENERIC_TEMP_ERROR,
  useBanners,
} from '@pumpkincare/shared';
import { useBooleanInput, useTargetState } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LoaderButton,
  RouteLink,
  SimpleHeader,
  TextField,
  Typography,
} from '@pumpkincare/shared/ui';
import {
  getReimbursementTargetAccountNumber,
  useDeleteUserAch,
  useMutateUserAch,
  usePayment,
} from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import validateDirectDepositFields from '../../utils/validate-direct-deposit-fields';
import DeleteDirectDepositModal from './delete-direct-deposit-modal';

import styles from './direct-deposit-setup-page.module.css';

// To test on non-prod environments, routing numbers MUST BE 222222226 according to Dwalla
function DirectDepositSetupPage() {
  const dispatch = useDispatch();

  const { data: paymentData, isError: isPaymentError } = usePayment();
  const hasAchSetup = !!getReimbursementTargetAccountNumber(paymentData);
  const { mutateAsync: mutateUserAch, isLoading: isMutatingUserAch } =
    useMutateUserAch();
  const { mutateAsync: deleteUserAch, isLoading: isDeletingUserAch } =
    useDeleteUserAch();
  const isLoading = isMutatingUserAch || isDeletingUserAch;

  const { addBanner, removeAllBanners } = useBanners();

  const [errorMessage, setErrorMessage] = useState('');
  const [accountHolderName, handleAccountHolderNameChange] = useTargetState('');
  const [accountNumber, handleAccountNumberChange] = useTargetState('');
  const [accountConfirmation, handleAccountConfirmationChange] = useTargetState('');
  const [routingNumber, handleRoutingNumberChange] = useTargetState('');
  const [routingConfirmation, handleRoutingConfirmationChange] = useTargetState('');
  const [isDeleteModalOpen, toggleIsDeleteModalOpen] = useBooleanInput(false);
  const [errors, setErrors] = useState({});

  const halfButtonsClassName = hasAchSetup ? styles.halfButtonsContainer : '';
  const accountInputLabel = `Checking Account Number`;
  const accountConfirmationInputLabel = `Confirm Checking Account Number`;

  function onSubmit(e) {
    e.preventDefault();

    removeAllBanners();

    const errorsValidation = validateDirectDepositFields(
      accountHolderName,
      accountNumber,
      accountConfirmation,
      routingNumber,
      routingConfirmation
    );

    if (errorsValidation.hasErrors) {
      setErrors(errorsValidation);
    } else {
      setErrors({});

      mutateUserAch({
        body: {
          name_on_account: accountHolderName,
          account_number: accountNumber,
          account_type: CHECKING,
          routing_number: routingNumber,
        },
      })
        .then(() => dispatch(push(Paths.DirectDepositSuccess)))
        .catch(error => {
          if (error.response && error.response.status === 400) {
            if (
              error.response.data.error_type === 'account' ||
              error.response.data.error_type === 'unknown'
            ) {
              addBanner({
                type: 'error',
                title: 'Ruh-roh! There’s an error from your bank.',
                message: error.response.data.message,
              });
            } else {
              setErrorMessage(error.response.data.message);
            }
          } else {
            addBanner(GENERIC_CONTACT_ERROR);
          }
        });
    }
  }

  const handleDelete = useCallback(
    e => {
      e.preventDefault();

      removeAllBanners();

      deleteUserAch()
        .then(() => dispatch(push(Paths.Claims)))
        .catch(() => addBanner(GENERIC_TEMP_ERROR))
        .finally(toggleIsDeleteModalOpen);
    },

    [removeAllBanners, deleteUserAch, toggleIsDeleteModalOpen, dispatch, addBanner]
  );

  function handleRoutingNumberFocus() {
    setErrors({
      ...errors,
      routingNumber: false,
    });
  }

  function handleAccountNumberFocus() {
    setErrors({
      ...errors,
      accountNumber: false,
    });
  }

  return (
    <>
      <SimpleHeader returnLink={Paths.Claims} />

      <div className={styles.directDepositContainer}>
        <h1>Direct Deposit</h1>

        <Body1 className={styles.bodyInfo}>
          Fill out the info below to get all reimbursements for pending & future
          claims directly deposited to your bank account. Only checking accounts are
          accepted at this time.
        </Body1>

        <Body2>Bank Account Details:</Body2>

        <form onSubmit={onSubmit} aria-label='directDepositForm'>
          <div className={styles.inputsContainer}>
            <TextField
              label='Account Holder Name'
              onChange={handleAccountHolderNameChange}
              defaultValue={accountHolderName}
              classes={{ container: styles.holderNameTextField }}
              error={{ hasError: errors.accountHolderName, isErrorHidden: false }}
              required
            />

            <TextField
              label={accountInputLabel}
              maxLength={20}
              onChange={handleAccountNumberChange}
              defaultValue={accountNumber}
              classes={{ container: styles.textField }}
              onFocus={handleAccountNumberFocus}
              error={{ hasError: errors.accountNumber }}
              required
            />

            <TextField
              label={accountConfirmationInputLabel}
              maxLength={20}
              onChange={handleAccountConfirmationChange}
              defaultValue={accountConfirmation}
              classes={{ container: styles.textField }}
              error={{
                errorMessage: errors.accountConfirmation
                  ? `Account numbers don't match.`
                  : '',
                isErrorHidden: false,
              }}
              required
            />

            <TextField
              label={'Routing Number'}
              placeholder={'Must be 9 digits long'}
              maxLength={9}
              onChange={handleRoutingNumberChange}
              defaultValue={routingNumber}
              classes={{ container: styles.textField }}
              onFocus={handleRoutingNumberFocus}
              error={{
                errorMessage:
                  errors.routingNumber || isPaymentError
                    ? (errors.routingNumber &&
                        !errors.routingConfirmation &&
                        'Please enter a 9 digit routing number') ||
                      errorMessage
                    : '',
                hasError: errors.routingNumber,
              }}
              required
            />

            <TextField
              label={'Confirm Routing Number'}
              maxLength={9}
              onChange={handleRoutingConfirmationChange}
              defaultValue={routingConfirmation}
              classes={{ container: styles.textField }}
              error={{
                errorMessage: errors.routingConfirmation
                  ? `Routing numbers don't match.`
                  : '',
                isErrorHidden: false,
              }}
              required
            />
          </div>

          <div className={styles.actionsContainer}>
            <button
              type='button'
              className={classNames(Typography.legalBody, styles.deleteButton)}
              disabled={hasAchSetup === false || isLoading || isPaymentError}
              onClick={toggleIsDeleteModalOpen}
            >
              Delete Direct Deposit
            </button>

            <div className={`${styles.buttonsContainer} ${halfButtonsClassName}`}>
              <LoaderButton
                color='primary'
                type='submit'
                isLoading={isLoading}
                disabled={isPaymentError}
                classes={{ root: styles.primaryButton }}
              >
                Save
              </LoaderButton>

              <RouteLink
                to={Paths.Claims}
                className={`${styles.redirectLink} ${halfButtonsClassName}`}
              >
                <button
                  className={classNames(
                    ButtonStyles.secondary,
                    styles.secondaryButton
                  )}
                >
                  Cancel
                </button>
              </RouteLink>
            </div>
          </div>
        </form>
      </div>

      {isDeleteModalOpen ? (
        <DeleteDirectDepositModal
          onSubmit={handleDelete}
          onCancel={toggleIsDeleteModalOpen}
          isLoading={isDeletingUserAch}
        />
      ) : null}
    </>
  );
}

export default DirectDepositSetupPage;

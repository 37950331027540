/* eslint-disable react/jsx-no-target-blank */
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { WWW_URL } from '@pumpkincare/config';
import { Typography } from '@pumpkincare/shared/ui';

import styles from './legal.module.css';

function Legal() {
  const { suppressMaine } = useFlags();

  return (
    <div className={styles.root}>
      <div className={styles.darkBlueWave} />

      <img
        className={styles.heartIcon}
        src='/assets/images/heart_scout_navy.svg'
        alt=''
      />

      <div className={styles.container}>
        <div>
          <div className={styles.link}>
            <a
              href={`${WWW_URL}/references/`}
              target='_blank'
              rel='noopener'
              className={styles.linkText}
            >
              SEE ALL REFERENCES
              <img src='/assets/images/iconImages/chevrons-right-white.svg' alt='' />
            </a>
          </div>

          <div className={styles.link}>
            <a
              href={`${WWW_URL}/california-privacy-disclosure/`}
              target='_blank'
              rel='noopener'
              className={classNames(
                Typography.legalBody,
                Typography.bold,
                styles.linkText
              )}
            >
              NOTICE TO CALIFORNIA RESIDENTS
              <img src='/assets/images/iconImages/chevrons-right-white.svg' alt='' />
            </a>
          </div>

          {suppressMaine ? (
            <div className={styles.link}>
              <a
                href={`${WWW_URL}/notice-to-maine-residents/`}
                target='_blank'
                rel='noopener'
                className={styles.linkText}
              >
                NOTICE TO MAINE RESIDENTS
                <img
                  src='/assets/images/iconImages/chevrons-right-white.svg'
                  alt=''
                />
              </a>
            </div>
          ) : null}

          <div>
            <p className={styles.legalText}>
              Pumpkin Pet Insurance policies do not cover pre-existing conditions.
              Waiting periods, annual deductible, co-insurance, benefit limits and
              exclusions may apply. For full terms, visit{' '}
              <a
                href={`${WWW_URL}/sample-plan/`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/insurancepolicy
              </a>
              . Products and rates may vary and are subject to change. Discounts may
              vary and are subject to change. Insurance is underwritten by United
              States Fire Insurance Company (NAIC #21113; Morristown, NJ), and
              administered and produced by Pumpkin Insurance Services Inc.
              (“Pumpkin”) (NPN #19084749; Domiciled in New York with offices at 432
              Park Avenue South, Floor 12, New York, NY 10016; CA License #6001617).
              Pumpkin is a licensed insurance agency, not an insurer, and receives
              compensation based on the premiums for the insurance policies it sells.
              For more details, visit{' '}
              <a
                href={`${WWW_URL}/underwriting-information`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/underwriting-information
              </a>
              . Pumpkin Preventive Essentials is not an insurance policy. It is
              offered as an optional add-on non-insurance benefit. Pumpkin is
              responsible for the product and administration. For full terms, visit{' '}
              <a
                href={`${WWW_URL}/sample-plan`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/customeragreement
              </a>
              . Pumpkin Preventive Essentials is not available in all states.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Legal;

import { useState } from 'react';

import { getActivePlan, isPrevent4, parsePlanDetails } from '@pumpkincare/plans';
import { calculateAndFormatISODate, formatPlanDates } from '@pumpkincare/shared';
import { Body1, LegalBody, Spinner } from '@pumpkincare/shared/ui';

import { downloadCustomPlanCa } from '../../utils/policy-utils';
import PreventPlanDescription from '../prevent-plan-description';

import styles from './prevent.module.css';

function Prevent({ pet }) {
  const [loading, setLoading] = useState(false);

  const { species } = pet;
  const activePlan = getActivePlan(pet.plans);

  const formattedPlanDates = activePlan
    ? formatPlanDates(activePlan.plan_effective_date)
    : null;

  const planStartDate = formattedPlanDates?.startDate;
  const planEndDate = formattedPlanDates?.endDate;
  const planRenewalStartDate = activePlan
    ? calculateAndFormatISODate(activePlan.plan_effective_date, '+1y', {
        inputTimezone: 'local',
      })
    : null;

  const planItems = parsePlanDetails(activePlan.plan, species);

  function handleDownloadClick(petPlanId) {
    setLoading(true);
    downloadCustomPlanCa(petPlanId).then(() => setLoading(false));
  }

  return (
    <>
      <div className={styles.pumpkinSection}>
        <div className={styles.pumpkinLeft}>
          <Body1>Pumpkin Preventive Essentials</Body1>
          {isPrevent4(activePlan.plan) && activePlan.plan.vet_name ? (
            <div>
              <img
                src={`/assets/images/iconImages/design-star.png`}
                role='presentation'
                className={styles.designStar}
                data-testid='little-star-mc-img'
              />
              <LegalBody className={styles.designDetails}>
                Designed by {activePlan.plan.vet_name}
              </LegalBody>
            </div>
          ) : null}
          <div>
            <LegalBody className={styles.protectDetails}>
              Period: {planStartDate} - {planEndDate}
            </LegalBody>
            <LegalBody className={styles.protectDetails}>
              Effective Renewal Date: {planRenewalStartDate}
            </LegalBody>
          </div>
        </div>

        <div className={styles.pumpkinRight}>
          <PreventPlanDescription planItems={planItems} />
        </div>
      </div>

      <LegalBody
        onClick={() => handleDownloadClick(activePlan.id)}
        className={styles.downloadPolicy}
      >
        {loading ? <Spinner size={12} /> : 'Download Customer Agreement'}
      </LegalBody>
    </>
  );
}

export default Prevent;

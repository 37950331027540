import { useState } from 'react';
import Store from 'store';

import { GaLink } from '@pumpkincare/analytics/ui';
import {
  ARF_MEOW_PHONE_NUMBER,
  PHONE_ARIA_LABEL,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { MAINTENANCE_MODAL_HAS_SHOWN_COOKIE } from '@pumpkincare/shared';
import { Modal } from '@pumpkincare/shared/ui';

import styles from './maintenance-modal.module.css';

function MaintenanceModal({ timeStart = 'DATE/TIME', timeEnd = 'DATE/TIME' }) {
  const maintenanceModaCookie = Store.get(MAINTENANCE_MODAL_HAS_SHOWN_COOKIE);
  const [isOpen, setIsOpen] = useState(!maintenanceModaCookie);

  function handleOnClose(type) {
    if (type == 'icon') {
      Store.set(MAINTENANCE_MODAL_HAS_SHOWN_COOKIE, true);
      setIsOpen(false);
    }
  }
  return isOpen ? (
    <Modal
      onClose={type => handleOnClose(type)}
      classes={{ container: styles.container, content: styles.content }}
    >
      <div className={styles.topIconContainer}>
        <img
          src='/assets/images/maintenance-icon.png'
          aria-hidden='true'
          alt='Seal'
          className={styles.topIcon}
        />
      </div>
      <h3 className={styles.modalTitle}>Scheduled Maintenance! </h3>
      <span>
        Pumpkin will be unavailable at <b>{timeStart}</b> to <b>{timeEnd}</b> as the
        team will be performing scheduled maintenance at this time. <br />
        We just need some time to spruce things up. <br />
        <b>
          Have questions?
          <br />
          We’re just a paw away.
        </b>
        <br />
        <GaLink
          className={styles.phone}
          href={PHONE_NUMBER_LINK}
          aria-label={PHONE_ARIA_LABEL}
          data-event={'Click Phone Number'}
          data-category={'About Pumpkin'}
          data-label={'Navigation Bar'}
        >
          {ARF_MEOW_PHONE_NUMBER}
        </GaLink>
      </span>
    </Modal>
  ) : null;
}
export default MaintenanceModal;

import { getActivePolicy } from '../../user';

export function findInsuranceStartDate(policies) {
  if (!policies || policies.length === 0) {
    return null;
  }

  const oldPolicies = policies.filter(
    policy => policy.status === 'expired' || policy.status === 'cancelled'
  );

  if (oldPolicies.length > 0) {
    return oldPolicies[0].policy_illness_effective_date;
  }

  const activePolicy = getActivePolicy(policies);

  if (activePolicy) {
    return activePolicy.policy_illness_effective_date;
  }

  return null;
}

const SET_BILLING_ADDRESS = 'user/SET_BILLING_ADDRESS';
const SET_USER_EMAIL_ADDRESS = 'user/SET_USER_EMAIL_ADDRESS';
const LEAVE_AGENT_MODE = 'user/LEAVE_AGENT_MODE';
const SET_AGENT = 'user/SET_AGENT';
const SET_SELF = 'user/SET_SELF';
const SET_REGISTRATION_ERROR = 'user/SET_REGISTRATION_ERROR';
const SET_IS_REGISTRATION_LOADING = 'user/SET_IS_REGISTRATION_LOADING';
const SET_USER_VET = 'user/SET_USER_VET';

const initialState = {
  id: null,
  firstName: null,
  lastName: null,

  externalId: null,
  email: null,
  phone: null,

  billingAddress: {
    firstName: null,
    lastName: null,
    zipcode: null,
  },

  shippingAddress: {
    street1: null,
    street2: null,
    zipcode: null,
    stateProvince: null,
    city: null,
  },

  shippingAddressUpdated: null,
  charges: null,
  claims: null,
  pets: [],
  plans: null,
  policies: [],
  invoices: [],

  registrationError: {
    isTokenError: false,
    errorMessage: '',
  },

  claimPreExModal: null,

  isRegistrationLoading: false,
  agentId: '',
};

export default function userReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_USER_EMAIL_ADDRESS: {
      return { ...state, email: payload };
    }

    case SET_BILLING_ADDRESS: {
      return { ...state, billingAddress: payload };
    }

    case SET_SELF: {
      const { users, invoices } = payload;

      const {
        id,
        first_name,
        last_name,
        external_id,
        email,
        addresses,
        pets,
        phone,
        lapsed_since,
        is_charged_annually,
        transaction_fee,
      } = users;

      const [pet] = pets;
      let plans = [];
      let policies = [];

      if (pet) {
        plans = pet.plans;
        policies = pet.policies;
      }

      let billingAddress = addresses.find(address => address.type === 'billing');

      if (billingAddress) {
        billingAddress = {
          id: billingAddress.id,
          firstName: billingAddress.first_name,
          lastName: billingAddress.last_name,
          street1: billingAddress.street_1,
          street2: billingAddress.street_2,
          zipcode: billingAddress.zipcode,
          stateProvince: billingAddress.state_province,
          city: billingAddress.city,
        };
      } else {
        billingAddress = {
          firstName: null,
          lastName: null,
          street1: null,
          street2: null,
          zipcode: null,
          stateProvince: null,
          city: null,
        };
      }

      let shippingAddress = addresses.find(address => address.type === 'shipping');
      if (shippingAddress) {
        shippingAddress = {
          id: shippingAddress.id,
          street1: shippingAddress.street_1,
          street2: shippingAddress.street_2,
          zipcode: shippingAddress.zipcode,
          stateProvince: shippingAddress.state_province,
          city: shippingAddress.city,
        };
      } else {
        shippingAddress = {
          street1: null,
          street2: null,
          zipcode: null,
          stateProvince: null,
          city: null,
        };
      }

      const externalId = `PKN${external_id}`;

      return {
        ...state,
        id,
        firstName: first_name,
        lastName: last_name,
        externalId,
        email,
        phone,
        billingAddress,
        shippingAddress,
        pets,
        lapsedSince: lapsed_since,
        plans,
        policies,
        invoices,
        is_charged_annually,
        transaction_fee,
      };
    }

    case SET_REGISTRATION_ERROR: {
      return {
        ...state,
        registrationError: {
          ...state.registrationError,
          ...payload,
        },
      };
    }

    case SET_IS_REGISTRATION_LOADING: {
      return {
        ...state,
        isRegistrationLoading: payload,
      };
    }

    case SET_USER_VET: {
      return {
        ...state,
        vet: payload,
      };
    }

    default: {
      return state;
    }
  }
}

export function setUserRegistrationError(payload) {
  return {
    type: SET_REGISTRATION_ERROR,
    payload,
  };
}

export function setUserIsRegistrationLoading(payload) {
  return {
    type: SET_IS_REGISTRATION_LOADING,
    payload,
  };
}

export function setUserEmail(newEmail) {
  return { type: SET_USER_EMAIL_ADDRESS, payload: newEmail };
}

export function setUserVet(payload) {
  return { type: SET_USER_VET, payload };
}

export function setUserSelf(payload) {
  return { type: SET_SELF, payload };
}

export function setBillingAddress(payload) {
  return {
    type: SET_BILLING_ADDRESS,
    payload,
  };
}

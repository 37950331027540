import { createSelector } from 'reselect';

import { getUserLapsedSince } from '../../user';
import { getBillingCreditCardUpdatedUpdated } from './billing-selectors';

const getShouldDisplayLapsedBanner = createSelector(
  [getUserLapsedSince, getBillingCreditCardUpdatedUpdated],
  (lapsedSince, paymentUpdated) => {
    return !!lapsedSince || paymentUpdated;
  }
);

export default getShouldDisplayLapsedBanner;

import { useState } from 'react';
import classNames from 'classnames';

import { checkPasswordRequirements, useBooleanInput } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  CheckCircle,
  CheckIcon,
  CloseIcon,
  LegalBody,
  LoaderButton,
  TextField,
  Typography,
  Visibility,
  VisibilityOff,
} from '@pumpkincare/shared/ui';
import { changePassword } from '@pumpkincare/user';

import styles from './my-password.module.css';

function MyPassword() {
  const [isEditing, setIsEditing] = useState(false);
  const [existingPassword, updateExistingPassword] = useState('');
  const [newPassword, updateNewPassword] = useState('');
  const [confirmPassword, updateConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [passwordVisibility, togglePasswordVisibility] = useBooleanInput(false);
  const [confirmPasswordVisibility, toggleConfirmPasswordVisibility] =
    useBooleanInput(false);
  const [passwordValidation, setPasswordValidation] = useState({ hasErrors: true });
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const passwordHasAtLeast12char = classNames(styles.passwordBody, {
    [styles.passwordConditionChecked]: passwordValidation.hasAtLeast12Char,
  });

  const passwordHasAtLeastOneLowercaseAndUppercase = classNames(
    styles.passwordBody,
    {
      [styles.passwordConditionChecked]:
        passwordValidation.hasAtLeastOneLowercase &&
        passwordValidation.hasAtLeastOneUppercase,
    }
  );
  const passwordHasAtLeastOneDigit = classNames(styles.passwordBody, {
    [styles.passwordConditionChecked]: passwordValidation.hasAtLeastOneDigit,
  });
  const passwordHasAtLeastOneSpecialChar = classNames(styles.passwordBody, {
    [styles.passwordConditionChecked]: passwordValidation.hasAtLeastOneSpecialChar,
  });
  const saveButtonClassname = classNames(
    styles.editingModeButton,
    styles.saveButton,
    {
      [styles.saveButtonDisabled]: passwordValidation.hasErrors,
    }
  );

  function onClickSaveChanges() {
    if (existingPassword === '' || newPassword === '' || confirmPassword === '') {
      setErrorMessage('All fields are required.');
      return;
    } else if (newPassword !== confirmPassword) {
      setPasswordConfirmationError('Password does not match.');
      return;
    } else if (checkPasswordRequirements(newPassword).hasErrors) {
      setPasswordError('Password does not meet requirements.');
      return;
    } else {
      setPasswordConfirmationError('');
      setPasswordError('');
      setErrorMessage('');
    }

    setLoading(true);

    changePassword(existingPassword, newPassword)
      .then(res => {
        setLoading(false);
        if (res === 'SUCCESS') {
          setSuccess(true);
          setIsEditing(false);
          updateExistingPassword('');
          updateNewPassword('');
          updateConfirmPassword('');
        }
        setLoading(false);
      })
      .catch(error => {
        if (error && error.message) {
          setErrorMessage(error.message);
        }

        setSuccess(false);
        setLoading(false);
      });
  }

  function handlePasswordChange(e) {
    updateNewPassword(e.target.value);
    setPasswordValidation(checkPasswordRequirements(e.target.value));
  }

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <h4>My Password</h4>
          {success && (
            <div className={styles.successContainer}>
              <LegalBody className={styles.successText}>
                Password updated successfully
              </LegalBody>
              <CheckCircle className={styles.check} />
            </div>
          )}
        </div>
        <div className={styles.editButtonWrapper}>
          {!isEditing && (
            <button
              className={classNames(ButtonStyles.secondary, styles.editButton)}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </button>
          )}
        </div>
      </div>

      {isEditing ? (
        <div>
          <div className={styles.editContainer}>
            <div className={styles.fieldsContainer}>
              <TextField
                label='Enter Existing Password'
                type='password'
                value={existingPassword}
                onChange={e => updateExistingPassword(e.target.value)}
                classes={{ container: styles.textField }}
              />
              <TextField
                label='Enter New Password'
                type={passwordVisibility ? 'text' : 'password'}
                value={newPassword}
                onChange={handlePasswordChange}
                classes={{ container: styles.textField }}
                endAdornment={{
                  endCustom: (
                    <button
                      aria-label='toggle password visibility'
                      className={styles.visibilityToggle}
                      onClick={togglePasswordVisibility}
                      type='button'
                    >
                      {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                    </button>
                  ),
                }}
                error={{
                  errorMessage: passwordError,
                }}
              />
              <TextField
                label='Confirm New Password'
                type={confirmPasswordVisibility ? 'text' : 'password'}
                value={confirmPassword}
                onChange={e => updateConfirmPassword(e.target.value)}
                onFocus={() => setPasswordConfirmationError('')}
                classes={{ container: styles.textField }}
                endAdornment={{
                  endCustom: (
                    <button
                      aria-label='toggle confirm password visibility'
                      className={styles.visibilityToggle}
                      onClick={toggleConfirmPasswordVisibility}
                      type='button'
                    >
                      {confirmPasswordVisibility ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </button>
                  ),
                }}
                error={{
                  errorMessage: passwordConfirmationError,
                }}
              />
              <div className={classNames(Typography.legalBody, styles.formError)}>
                {errorMessage}
              </div>
            </div>
            <div>
              <Body2 className={styles.passwordSubhead}>
                Password must include:
              </Body2>
              <Body1 className={passwordHasAtLeast12char}>
                {passwordValidation.hasAtLeast12Char ? <CheckIcon /> : <CloseIcon />}
                At least 12 characters in length
              </Body1>
              <Body1 className={passwordHasAtLeastOneLowercaseAndUppercase}>
                {passwordValidation.hasAtLeastOneLowercase &&
                passwordValidation.hasAtLeastOneUppercase ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )}
                At least one UPPERCASE & one lowercase letter
              </Body1>
              <Body1 className={passwordHasAtLeastOneDigit}>
                {passwordValidation.hasAtLeastOneDigit ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )}
                At least one number
              </Body1>
              <Body1 className={passwordHasAtLeastOneSpecialChar}>
                {passwordValidation.hasAtLeastOneSpecialChar ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )}

                {`At least one special character, such as: ^ { } ( ) ! @ # % & ~`}
              </Body1>
            </div>
          </div>
          <div className={styles.editButtonsContainer}>
            <LoaderButton
              color='primary'
              disabled={passwordValidation.hasErrors}
              classes={{ root: saveButtonClassname }}
              onClick={onClickSaveChanges}
              isLoading={loading}
            >
              Save Changes
            </LoaderButton>
            <button
              className={classNames(
                ButtonStyles.secondary,
                styles.editingModeButton,
                styles.cancelButton
              )}
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MyPassword;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".payment-lapsed-banner_root_422ed9f8 {\n  width: 100%;\n  padding: 15px 8.333333%;\n  background-color: var(--crookshanksRed);\n  box-sizing: border-box;\n}\n\n.payment-lapsed-banner_error_3402b63e {\n  background-color: var(--crookshanksRed);\n}\n\n.payment-lapsed-banner_info_17b1cf72 {\n  background-color: var(--astroGreen);\n}\n\n.payment-lapsed-banner_title_906fc56c, .payment-lapsed-banner_description_5b1d677f, .payment-lapsed-banner_link_95d36719 {\n  color: var(--zeroWhite);\n}\n\n.payment-lapsed-banner_link_95d36719 {\n  text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://src/billing/view/payment-lapsed-banner/payment-lapsed-banner.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;EACvB,uCAAuC;EACvC,sBAAsB;AACxB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".root {\n  width: 100%;\n  padding: 15px 8.333333%;\n  background-color: var(--crookshanksRed);\n  box-sizing: border-box;\n}\n\n.error {\n  background-color: var(--crookshanksRed);\n}\n\n.info {\n  background-color: var(--astroGreen);\n}\n\n.title, .description, .link {\n  color: var(--zeroWhite);\n}\n\n.link {\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "payment-lapsed-banner_root_422ed9f8",
	"error": "payment-lapsed-banner_error_3402b63e",
	"info": "payment-lapsed-banner_info_17b1cf72",
	"title": "payment-lapsed-banner_title_906fc56c",
	"description": "payment-lapsed-banner_description_5b1d677f",
	"link": "payment-lapsed-banner_link_95d36719"
};
export default ___CSS_LOADER_EXPORT___;

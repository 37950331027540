// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".direct-deposit-info_headerWrapper_a3cef767 {\n  display: flex;\n  width: 83.3%;\n  justify-content: space-between;\n}\n\n.direct-deposit-info_contentContainer_656925f7 {\n  padding-bottom: 35px;\n}\n\n.direct-deposit-info_redirectLink_42b0b1fc {\n  text-decoration: none;\n}\n\n.direct-deposit-info_directDepositText_62b9f67a {\n  margin-bottom: 16px;\n}\n\n.direct-deposit-info_editButton_865c4a11 {\n  margin: 20px 0 0;\n  width: 72px;\n  min-height: 40px;\n}", "",{"version":3,"sources":["webpack://src/claims/view/direct-deposit-info/direct-deposit-info.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".headerWrapper {\n  display: flex;\n  width: 83.3%;\n  justify-content: space-between;\n}\n\n.contentContainer {\n  padding-bottom: 35px;\n}\n\n.redirectLink {\n  text-decoration: none;\n}\n\n.directDepositText {\n  margin-bottom: 16px;\n}\n\n.editButton {\n  margin: 20px 0 0;\n  width: 72px;\n  min-height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": "direct-deposit-info_headerWrapper_a3cef767",
	"contentContainer": "direct-deposit-info_contentContainer_656925f7",
	"redirectLink": "direct-deposit-info_redirectLink_42b0b1fc",
	"directDepositText": "direct-deposit-info_directDepositText_62b9f67a",
	"editButton": "direct-deposit-info_editButton_865c4a11"
};
export default ___CSS_LOADER_EXPORT___;

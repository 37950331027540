import { useRef } from 'react';
import classNames from 'classnames';

import { FETCHING } from '@pumpkincare/shared';
import {
  ButtonStyles,
  LoaderButton,
  Modal,
  Typography,
} from '@pumpkincare/shared/ui';
import { VetClinic } from '@pumpkincare/vets';

import usePetsPermissions from '../../hooks/use-pets-permissions';
import ClinicsModalBody from '../clinics-modal-body';

import styles from './clinics-new-modal.module.css';

export default function ClinicsNewModal({ onClose, onSubmit, userPets, status }) {
  const {
    petAssociations,
    handlePetAssociationsChange,
    claimsPermitted,
    toggleClaimsPermission,
  } = usePetsPermissions(userPets);
  const vet = useRef({});
  const isLoading = status === FETCHING;

  function handleSubmit() {
    if (vet.current.id) {
      onSubmit(vet.current.id, {
        pet_associations: petAssociations,
        can_file_claim: claimsPermitted,
      });
    }
  }

  function handleChange(payload) {
    vet.current = payload.existing || {};
  }

  return (
    <Modal
      onClose={onClose}
      classes={{ container: styles.container, content: styles.content }}
    >
      <h2 className={styles.header}>Add a Vet Clinic</h2>

      <VetClinic onChange={handleChange} canShowForm={false} />

      <ClinicsModalBody
        userPets={userPets}
        petAssociations={petAssociations}
        onPetChange={handlePetAssociationsChange}
        claimsPermitted={claimsPermitted}
        toggleClaimsPermission={toggleClaimsPermission}
      />

      <LoaderButton
        color='primary'
        classes={{ root: styles.button }}
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        Save Clinic
      </LoaderButton>
      <button
        className={classNames(
          ButtonStyles.baseButton,
          Typography.body2,
          styles.cancel
        )}
        onClick={onClose}
      >
        Cancel
      </button>
    </Modal>
  );
}

import { useState } from 'react';

import { useBooleanInput } from '@pumpkincare/shared';

export default function usePetsPermissions(userPets, vet = {}) {
  const [petAssociations, setPetAssociations] = useState(
    userPets.reduce((result, pet) => {
      result[pet.id] = !!vet.pets?.find(vetPet => pet.id === vetPet.id);
      return result;
    }, {})
  );
  const [claimsPermitted, toggleClaimsPermission] = useBooleanInput(
    vet.permissions &&
      !(vet.permissions.needsClaimAction || vet.permissions.needsDataAction)
      ? vet.permissions.can_file_claim
      : true
  );

  function handlePetAssociationsChange(id) {
    setPetAssociations(state => ({
      ...state,
      [id]: !state[id],
    }));
  }

  return {
    petAssociations,
    handlePetAssociationsChange,
    claimsPermitted,
    toggleClaimsPermission,
  };
}

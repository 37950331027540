import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

import { Body1, Body2, ButtonStyles } from '@pumpkincare/shared/ui';
import {
  getReimbursementTargetAccountNumber,
  getReimbursementTargetAccountType,
  getReimbursementTargetNameOnAccount,
  usePayment,
} from '@pumpkincare/user';

import { Paths } from '../../../app-shell';

import styles from './direct-deposit-info.module.css';

function DirectDepositInfo() {
  const { data: paymentData } = usePayment();
  const isAchSetup = !!getReimbursementTargetAccountNumber(paymentData);
  const nameOnAccount = getReimbursementTargetNameOnAccount(paymentData);
  const accountType = getReimbursementTargetAccountType(paymentData);
  const accountNumber = getReimbursementTargetAccountNumber(paymentData);

  const addDirectDeposit = (
    <div>
      <Body2>Want to get paid back faster?</Body2>

      <Body1>Set up direct deposit today.</Body1>

      <Link to={Paths.DirectDeposit} className={styles.redirectLink}>
        <button className={ButtonStyles.secondary}>Add Direct Deposit</button>
      </Link>
    </div>
  );

  const directDepositSummary = (
    <div>
      <Body2>{nameOnAccount}</Body2>

      <Body1>{`${_.capitalize(accountType)} ending in ${accountNumber.substring(
        accountNumber.length - 4
      )}`}</Body1>
    </div>
  );

  return (
    <Fragment>
      <div className={styles.headerWrapper}>
        <h4 className={styles.directDepositText}>{`Direct Deposit`}</h4>

        {isAchSetup ? (
          <div style={{ justifyContent: 'flex-end' }}>
            <Link to={Paths.DirectDeposit} className={styles.redirectLink}>
              <button
                className={classNames(ButtonStyles.secondary, styles.editButton)}
              >
                Edit
              </button>
            </Link>
          </div>
        ) : null}
      </div>

      <div className={styles.contentContainer}>
        {isAchSetup ? directDepositSummary : addDirectDeposit}
      </div>
    </Fragment>
  );
}

export default DirectDepositInfo;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".account-billing-wrapper_container_a0431d4c,\n.account-billing-wrapper_header_1ba0aaf3 {\n  width: calc(2/3 * 100%);\n}\n\n.account-billing-wrapper_accountDivider_bdd93080 {\n  width: 100%;\n}\n\n.account-billing-wrapper_billingWrapper_ad1eed57 {\n  width: 100%;\n  max-width: 1440px;\n  margin: 0 var(--column2);\n}", "",{"version":3,"sources":["webpack://src/billing/view/account-billing-wrapper/account-billing-wrapper.module.css"],"names":[],"mappings":"AAAA;;EAEE,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,wBAAwB;AAC1B","sourcesContent":[".container,\n.header {\n  width: calc(2/3 * 100%);\n}\n\n.accountDivider {\n  width: 100%;\n}\n\n.billingWrapper {\n  width: 100%;\n  max-width: 1440px;\n  margin: 0 var(--column2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "account-billing-wrapper_container_a0431d4c",
	"header": "account-billing-wrapper_header_1ba0aaf3",
	"accountDivider": "account-billing-wrapper_accountDivider_bdd93080",
	"billingWrapper": "account-billing-wrapper_billingWrapper_ad1eed57"
};
export default ___CSS_LOADER_EXPORT___;

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Body1, Body2 } from '@pumpkincare/shared/ui';

import { Paths } from '../../../app-shell';
import { getUserLapsedSince } from '../../../user/selectors/user-selectors';
import { getBillingCreditCardUpdatedUpdated } from '../../selectors/billing-selectors';

import styles from './payment-lapsed-banner.module.css';

function PaymentLapsedBanner() {
  const isLapsed = !!useSelector(getUserLapsedSince);
  const paymentUpdated = useSelector(getBillingCreditCardUpdatedUpdated);

  const rootClassName = classNames(styles.root, {
    [styles.error]: isLapsed,
    [styles.info]: paymentUpdated,
  });

  const content = paymentUpdated
    ? {
        title: 'Thank you for updating your payment method.',
        description:
          'We will process your payment with the card we have for you on file. Your Pumpkin plan will be updated.',
      }
    : {
        title: 'Your payment is past due.',
        description:
          'We are unable to process your payment due to an issue with your payment method.',
      };

  const showAction = isLapsed && !paymentUpdated;

  return (
    <div className={rootClassName}>
      <Body2 className={styles.title}>{content.title}</Body2>

      <Body1 className={styles.description}>
        {content.description}{' '}
        {showAction ? (
          <Link
            role={'link'}
            to={`${Paths.Billing}#edit-payment-method`}
            className={styles.link}
          >
            Please update your payment method.
          </Link>
        ) : null}
      </Body1>
    </div>
  );
}

export default PaymentLapsedBanner;

import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  formatISODate,
  formatMonthlyStatementsGroupTitle,
} from '@pumpkincare/shared';
import { Modal, Typography } from '@pumpkincare/shared/ui';
import { sortMonthlyStatement } from '@pumpkincare/user';

import { LAPSED, PAST_DUE } from '../../../user';

import styles from './charge-group-statements-modal.module.css';

function ChargeGroupStatementsModal({ handleCancel, data }) {
  const petNames = formatMonthlyStatementsGroupTitle(data.petNames);

  const disputeStatuses = {
    dispute_warning: 'In Dispute',
    dispute_review: 'In Dispute',
    dispute_won: 'Dispute Closed - Paid',
    dispute_lost: 'Dispute Closed - Refunded',
  };

  function createRow(invoice) {
    const isLapsed = invoice.status === LAPSED;
    const disputeStatus =
      disputeStatuses[invoice.dispute_status] || invoice.dispute_status;

    const date = formatISODate(invoice.created_at, {
      format: 'MMM D, YYYY',
    });
    const total = invoice.total
      ? `$${(parseFloat(invoice.total) / 100).toFixed(2)}`
      : '';
    const status = disputeStatus
      ? disputeStatus
      : isLapsed
      ? PAST_DUE
      : invoice.status;

    const trClassName = classNames(styles.tr, {
      [styles.lapsed]: isLapsed,
    });

    return (
      <tr
        className={trClassName}
        key={invoice.id}
        data-testid={`invoice-${invoice.id}`}
      >
        <td className={styles.td} role={'cell'} data-testid={'cell-date'}>
          {date}
        </td>
        <td className={styles.td} role={'cell'} data-testid={'cell-total'}>
          {total}
        </td>
        <td
          className={classNames(styles.td, styles.status)}
          role={'cell'}
          data-testid={'cell-status'}
        >
          {status}
        </td>
        <td
          className={classNames(styles.td, styles.tdBig)}
          role={'cell'}
          data-testid={'cell-receipt'}
        >
          {invoice.receipt_url ? (
            <a
              href={invoice.receipt_url}
              className={styles.receiptLink}
              target='_blank'
              rel='noopener noreferrer'
              role={'link'}
              data-testid={'cell-receipt-link'}
            >
              View Monthly Statement
            </a>
          ) : (
            ''
          )}
        </td>
      </tr>
    );
  }

  return (
    <Modal
      onClose={handleCancel}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      classes={{ content: styles.container }}
    >
      <h1 id='modal-title' className={styles.title} role='heading' aria-level='1'>
        Monthly Statements
      </h1>

      <h3
        className={styles.petNames}
        role='heading'
        aria-level='2'
        id='modal-description'
      >
        {petNames}
      </h3>

      <table className={classNames(Typography.legalBody, styles.table)}>
        <thead />
        <tbody>{sortMonthlyStatement(data.invoices).map(createRow)}</tbody>
      </table>
    </Modal>
  );
}

export default ChargeGroupStatementsModal;

ChargeGroupStatementsModal.propTypes = {
  data: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';

import {
  Body1,
  Body2,
  ButtonStyles,
  LoaderButton,
  Modal,
} from '@pumpkincare/shared/ui';

import styles from './delete-direct-deposit-modal.module.css';

function DeleteDirectDepositModal({ onSubmit, onCancel, isLoading }) {
  return (
    <Modal
      onClose={onCancel}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      classes={{ content: styles.modalContainer }}
    >
      <Body2 id='modal-title' className={styles.subheadText}>
        Are you sure you want to delete direct deposit?
      </Body2>

      <Body1 id='modal-description' className={styles.bodyText}>
        You will receive paper or echecks unless you have direct deposit set up.
      </Body1>

      <div className={styles.modalButtonsContainer}>
        <button
          className={ButtonStyles.secondary}
          onClick={onCancel}
          disabled={isLoading}
        >
          No
        </button>

        <LoaderButton color='primary' onClick={onSubmit} isLoading={isLoading}>
          Yes, Delete Direct Deposit
        </LoaderButton>
      </div>
    </Modal>
  );
}

DeleteDirectDepositModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteDirectDepositModal;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h2.clinics-update-modal_header_1f3a0f5f {\n    color: var(--bluesCluesBlue);\n}\n\n.clinics-update-modal_clinic_afbb6a61 {\n    padding: 12px 8px;\n    border: 1px solid var(--porkchopGray);\n}\n\nbutton.clinics-update-modal_button_51d34a11 {\n    display: block;\n    margin: 20px auto 0;\n    width: 100%;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n    .clinics-update-modal_content_ed02c23e {\n        padding: 32px 16%;\n    }\n}", "",{"version":3,"sources":["webpack://src/user/view/clinics-update-modal/clinics-update-modal.module.css"],"names":[],"mappings":"AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["@value b-desktop from '~@pumpkincare/shared/ui/breakpoints';\n\nh2.header {\n    color: var(--bluesCluesBlue);\n}\n\n.clinic {\n    padding: 12px 8px;\n    border: 1px solid var(--porkchopGray);\n}\n\nbutton.button {\n    display: block;\n    margin: 20px auto 0;\n    width: 100%;\n}\n\n@media b-desktop {\n    .content {\n        padding: 32px 16%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"header": "clinics-update-modal_header_1f3a0f5f",
	"clinic": "clinics-update-modal_clinic_afbb6a61",
	"button": "clinics-update-modal_button_51d34a11",
	"content": "clinics-update-modal_content_ed02c23e"
};
export default ___CSS_LOADER_EXPORT___;

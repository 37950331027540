const SET_STRIPE_TOKEN = 'billing/SET_STRIPE_TOKEN';
const SET_STRIPE_ERROR = 'billing/SET_STRIPE_ERROR';
const UPDATE_CREDIT_CARD = 'billing/UPDATE_CREDIT_CARD';

const initialState = {
  stripeToken: null,
  stripeError: false,

  creditCardUpdated: {
    fetching: false,
    updated: false,
    error: null,
    errorMessage: null,
  },

  discounts: [],
};

export default function billingReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_STRIPE_TOKEN: {
      return { ...state, stripeToken: payload };
    }

    case SET_STRIPE_ERROR: {
      return { ...state, stripeError: payload };
    }

    case UPDATE_CREDIT_CARD: {
      return {
        ...state,
        creditCardUpdated: {
          fetching: payload.fetching,
          updated: payload.updated,
          error: payload.error,
          errorMessage: payload.errorMessage,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export function setStripeError(error) {
  return { type: SET_STRIPE_ERROR, payload: error };
}

export function setStripeToken(token) {
  return { type: SET_STRIPE_TOKEN, payload: token };
}

export function setUpdatedCreditCard(payload) {
  return {
    type: UPDATE_CREDIT_CARD,
    payload,
  };
}

export function setCreditCardUpdateError(errorMessage) {
  return {
    type: UPDATE_CREDIT_CARD,
    payload: {
      fetching: false,
      updated: false,
      error: true,
      errorMessage,
    },
  };
}

import { getDateRangeLabel } from '@pumpkincare/shared';

export function buildDateRangeLabel(policies) {
  return policies.reduce((acc, policy) => {
    acc[getDateRangeLabel(policy)] = policy;
    return acc;
  }, {});
}

export function sortClaims(claims) {
  return claims.sort(function (a, b) {
    return b.date_submitted.localeCompare(a.date_submitted);
  });
}

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".legal-text-modal_container_3c2e0898 {\n  padding: 16px;\n  border-radius: 4px;\n  justify-content: space-between;\n  margin-top: 16px;\n  margin-bottom: 24px;\n  border: 2px solid var(--plutoGold);\n}\n\n.legal-text-modal_closeIcon_ac4588eb {\n  cursor: pointer;\n}\n\n.legal-text-modal_closeDiv_57fe174c {\n  width: 108%;\n  text-align: right;\n}\n\n.legal-text-modal_legalText_4d980f21 {\n  padding-bottom: 24px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["upSm"] + " {\n  .legal-text-modal_legalText_4d980f21 {\n    padding-bottom: 24px;\n  }\n\n  .legal-text-modal_modalContainer_41217343 {\n    width: 960px;\n  }\n\n  .legal-text-modal_closeDiv_57fe174c {\n    text-align: right;\n    width: 860px;\n    padding-top: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/claims/view/claims-submission/legal-text-modal/legal-text-modal.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,gBAAgB;EAChB,mBAAmB;EACnB,kCAAkC;AACpC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,oBAAoB;EACtB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,YAAY;IACZ,cAAc;EAChB;AACF","sourcesContent":["@value upSm from '~@pumpkincare/shared/ui/breakpoints';\n\n.container {\n  padding: 16px;\n  border-radius: 4px;\n  justify-content: space-between;\n  margin-top: 16px;\n  margin-bottom: 24px;\n  border: 2px solid var(--plutoGold);\n}\n\n.closeIcon {\n  cursor: pointer;\n}\n\n.closeDiv {\n  width: 108%;\n  text-align: right;\n}\n\n.legalText {\n  padding-bottom: 24px;\n}\n\n@media upSm {\n  .legalText {\n    padding-bottom: 24px;\n  }\n\n  .modalContainer {\n    width: 960px;\n  }\n\n  .closeDiv {\n    text-align: right;\n    width: 860px;\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upSm": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["upSm"] + "",
	"container": "legal-text-modal_container_3c2e0898",
	"closeIcon": "legal-text-modal_closeIcon_ac4588eb",
	"closeDiv": "legal-text-modal_closeDiv_57fe174c",
	"legalText": "legal-text-modal_legalText_4d980f21",
	"modalContainer": "legal-text-modal_modalContainer_41217343"
};
export default ___CSS_LOADER_EXPORT___;

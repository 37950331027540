// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile_root_4c01c0da {\n  margin: 0 var(--w1);\n}", "",{"version":3,"sources":["webpack://src/user/view/profile/profile.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n  margin: 0 var(--w1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "profile_root_4c01c0da"
};
export default ___CSS_LOADER_EXPORT___;
